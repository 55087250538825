import { Box, Typography } from "@mui/material"
import loadingImage from "../../assets/Anima-o-logo-01-unscreen.gif"
export const Loading = ({hasProgress = false, progress = 0})=>{
    return(
    <Box sx={{position: "fixed", zIndex: 2000,height: "100%", width: "100%", display: "flex", 
    justifyContent: "center", alignItems: "center", backgroundColor:"rgba(0, 0, 0, 0.8)"}}>
        {

        hasProgress&&<Typography color={"#fff"} variant="body1" sx={{position: "absolute", display: "flex", justifyContent: "center", fontWeight: "bold",alignItems: "center"}}>{progress}</Typography>
        }
        <img src={loadingImage} height={250}>
        </img>
    </Box>
    )
}