import * as React from "react";
import { Alert, Box, Button, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "../../styles/DragAndDrop.module.css";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ButtonComp from "../Button/Button";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import { ImageView } from "../../contexts/imageView";
import { ModalComp } from "../Modal/Modal";
import {Alarm, Edit} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import {
  copySegmentPrevious,
  copySegmentPreviousAndNext,
  setSegmento,
} from "../../store/reducers/dadosSegmentos";
import ImageModal from "../imageModal/imageModal";
import { Loading } from "../Loading";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/auth.service";
export default function DragAndDrop() {
  const dispatch = useDispatch();
  const slices = useSelector((state) => state);
  const state = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const images = state.imagens
  const pages = state.length;
  const [page, setPage] = useState(state.paginaAtual);
  const [pagePreview, setPagePreview] = useState(state.paginaAtual);
  const [imagesView, setImagesView] = useState(
    images.slice(5 * page - 5, page * 5)
  );
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const [srcImageModal, setSrcImageModal] = useState('')
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalSave, setOpenModalSave] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [openModalImagens, setOpenModalImagens] = React.useState(false);
  const [itensFaltando, setItensFaltando] = useState([]);
  const { imageView, dispatchImageView } = React.useContext(ImageView);
  const [incompleteRows, setImcompleteRows] = useState([]);
  const [idHeader, setIdHeader] = useState();
  const [textFieldValue, setTextFieldValue] = useState(1);
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [imagesLoads, setImagesLoads] = useState(0)
  const navigate = useNavigate()
  let auth = authService.getLoggedUser();
  let {isAdm} = auth 

  const handleCloseImageModal = () =>{
    setOpenImageModal(false)
  }

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBackdropOpen = () =>{

    setOpenBackdrop(true)

  }
  const handleBackdropClose = () =>{

    setOpenBackdrop(false)
  }
  useEffect(()=>{
    if(openBackdrop){
      setTimeout(()=>{
        handleBackdropClose()
      }, 1000)
    }
   
    
  }, [openBackdrop])

  useEffect(()=>{
    console.log("segmentos")
  },[state.segmentos])

  const uploadSection = async (segmentos) => {
    const formData = new FormData();
    formData.append("header", state.idHeader);
    let id;
    Object.keys(segmentos).forEach((item) => {
      if (segmentos[item].varName === "id_section") {
        id = 0;
        id = segmentos[item].selecionado.code;
      }
      if (segmentos[item].selecionado.code) {
        formData.append(
          segmentos[item].varName,
          segmentos[item].selecionado.code
        );
      }
    });
    formData.delete("image_reference")
    formData.delete("centre_0")
    formData.delete("centre_20")
    formData.delete("centre_40")
    formData.delete("centre_60")
    formData.delete("centre_80")
    await pesquisaApi.uploadSections(formData, id);
  };
  
  const handleSaveSections = async () => {
    handleBackdropOpen()
  
            try{ 
              
             await uploadSection(state.segmentos[state.paginaAtual -1])
             setOpenSnackbar(true)
            } catch(error) {
              console.error('Erro na promessa:', error);
              setOpenSnackbarError(true)
              throw error; 
            }
          }

  async function contaItens(value, _hasSave = false) {
    let faltantes = [];
    const { segmentos } = state;
    const segmento = segmentos[state.paginaAtual - 1];
    Object.keys(segmento).forEach((i) => {
      if (!segmento[i].selecionado.code) {
        faltantes.push(segmento[i].title);
      }
    });
    setItensFaltando(faltantes);
    if(!_hasSave){

      setPagePreview(value);
      if (faltantes.length !== 0) {
        setOpenModal(!openModal);
      } else {
        setPage(value);
      }
    }else{
      if (faltantes.length !== 0) {
        setOpenModalSave(!openModalSave);
      } else {
        setOpenBackdrop(true)
        await handleSaveSections();
      }
    }
  }

  useEffect(() => {
    setImagesView(images.slice(5 * page - 5, page * 5));
    setSegment();
  }, [page]);
  useEffect(()=>{
      if(state.paginaAtual !== page) setPage(state.paginaAtual)
  },[state.paginaAtual])

  async function setSegment() {
    const { segmentos } = state;
    const segmento = segmentos[state.paginaAtual - 1];

    let stateLocal = [];
    Object.keys(segmento).forEach((i) => {
      stateLocal.push(segmento[i]);
    });
    await dispatch(setSegmento(page));
  }
  useEffect(() => {
    const { segmentos } = state;
    const segmento = segmentos[state.paginaAtual - 1];
    let faltantes = [];
    Object.keys(segmento).forEach((i) => {
      if (!segmento[i].selecionado.code) {
        faltantes.push(segmento[i].title);
      }
    });
    setItensFaltando(faltantes);
    setIdHeader(state.idHeader);
  }, []);

  return (
    <>
      {openBackdrop && <Loading/>}
    <div className={styles.container}>
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={()=>{ setOpenSnackbar(false)}}>
        <Alert severity="success">O trecho foi salvo com sucesso</Alert>
      </Snackbar>
      <Snackbar open={openSnackbarError} autoHideDuration={4000} onClose={()=>{ setOpenSnackbarError(false)}}>
        <Alert severity="error">Erro: Trecho não salvo. Tente novamente</Alert>
      </Snackbar>
      {openImageModal && <ImageModal images={imagesView} image={srcImageModal} open={openImageModal} handleClose={handleCloseImageModal} />}
      {incompleteRows.length != 0 ? (
            <div>
              <ModalComp
                open={openModalImagens}
                title={"Imagens ausentes ou duplicadas"}
                subtitle={"Estas linhas contêm imagens ausentes ou duplicadas:"}
              >
                {incompleteRows.map((item, i) => (
                  <p key={i} style={{ color: "#FF0000" }}>
                    {item}
                  </p>
                ))}

                <Button
                  onClick={() => {
                    setOpenModalImagens(!openModalImagens);
                  }}
                >
                  Ok
                </Button>
              </ModalComp>
            </div>
          ) : (
            <></>
          )}
        <>
          <div className={styles.preview}>
            {imagesView.map((images, i) => {
              // console.log(images);
              return (
                <button className={styles.image} key={i} onClick={()=>{
                  setSrcImageModal(images)
                  setOpenImageModal(true)
                }}>
                  <div className={styles.lupaContent}>

                  <ZoomInIcon fontSize="large"/>
                  </div>
                  <img src={images} alt={images} key={i} onLoad={()=>{
                    if(imagesLoads+1 < 5){
                      setOpenBackdrop(true)
                      setImagesLoads(imagesLoads++)
                    }else if(imagesLoads+1 > 5){
                      setImagesLoads(0)
                    }else{
                      setOpenBackdrop(false)

                    }
                  }} />
                </button>
              );
            })}
          </div>
          {
            <Box display={"flex"} alignItems={"center"}>
              {

              isAdm &&
              <ButtonComp
              sx={{marginRight: "10px"}}
              onClick={()=> navigate("/edit")}
              >Editar
              </ButtonComp>
              }
              <Typography>Trecho atual: {page}</Typography>
              <Pagination
                count={pages}
                page={page}
                sx={{marginRight:"10px"}}
                onChange={(event, value) => {
                  if (page != value) {
                    contaItens(value);
                  }
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <Box display={"flex"} alignItems={"center"}>
              <Typography mr={1}>Ir para: </Typography>
              <TextField 
  size="small" 
  variant="outlined" 
  sx={{width:"90px", marginRight: "10px"}} 
  type="number" 
  onChange={(e)=> setTextFieldValue(parseInt(e.target.value))} // Converter para número usando parseInt
/>
              <ButtonComp disabled={textFieldValue > pages || page === textFieldValue || textFieldValue <= 0} onClick={()=> {              
                    contaItens(parseInt(textFieldValue));
                }}>Ok</ButtonComp >
              </Box>
              
            </Box>
          }

          <div className={styles.divBtn}>
            <ButtonComp
              sx={{ marginRight: "20px", marginTop: "10px" }}
              onClick={() => {
                dispatchImageView({
                  type: "SET_STATE",
                });
              }}
            >
              Alterar visualização{" "}
            </ButtonComp>
            <ButtonComp
              sx={{ marginRight: "20px", marginTop: "10px" }}
              disabled={page - 1 <= 0}
              onClick={() => {
                if (page - 1 >= 0) {
                  contaItens(page - 1);
                }
              }}
            >
              Trecho anterior
            </ButtonComp>

            <ButtonComp
              sx={{ marginRight: "20px", marginTop: "10px" }}
              disabled={!(page + 1 <= pages)}
              onClick={() => {
                if (page + 1 <= pages) {
                  contaItens(page + 1);
                }
              }}
            >
              Próximo trecho
            </ButtonComp>
            <ButtonComp
              sx={{ marginRight: "20px", marginTop: "10px" }}
              disabled={!(page + 1 <= pages)}
              onClick={() => {
                if (page + 1 <= pages) {
                  dispatch(copySegmentPreviousAndNext());
                  contaItens(page + 1);
                }
              }}
            >
              Próximo trecho e copiar
            </ButtonComp>
            <ButtonComp
              onClick={async() => {
                contaItens(0, true)
              }}
              sx={{ marginRight: "20px", marginTop: "10px" }}
            >
              Salvar trecho
            </ButtonComp>
            <ButtonComp
             disabled={page - 1 <= 0}
              onClick={(e) => {
                e.preventDefault();
                if (page - 1 >= 0) dispatch(copySegmentPrevious());
              }}
            >
              Copiar trecho anterior
            </ButtonComp>
          </div>

          {itensFaltando.length != 0 ? (
            <div>
              <ModalComp
                open={openModalSave}
                title={" Tem certeza que deseja salvar o trecho?"}
                subtitle={" Os seguintes itens não foram prenchidos:"}
              >
                {itensFaltando.map((item, i) => (
                  <p key={i} style={{ color: "#FF0000" }}>
                    {item}
                  </p>
                ))}
                <p id="parent-modal-description">
                 Deseja continuar?
                </p>

                <Button
                  onClick={async() => {
                    setOpenModalSave(!openModalSave);
                    setOpenBackdrop(true)
                    await handleSaveSections();
                  }}
                >
                  Sim
                </Button>
                <Button onClick={()=> setOpenModalSave(false)}>Não</Button>
              </ModalComp>
            </div>
          ) : (
            <></>
          )}
          {itensFaltando.length != 0 ? (
            <div>
              <ModalComp
                open={openModal}
                title={" Tem certeza que deseja trocar o trecho analisado?"}
                subtitle={" Os seguintes itens não foram prenchidos:"}
              >
                {itensFaltando.map((item, i) => (
                  <p key={i} style={{ color: "#FF0000" }}>
                    {item}
                  </p>
                ))}
                <p id="parent-modal-description">
                  A codificação do trecho atual será perdida.
                </p>

                <Button
                  onClick={() => {
                    setPage(pagePreview);
                    setOpenModal(!openModal);
                  }}
                >
                  Sim
                </Button>
                <Button onClick={handleClose}>Não</Button>
              </ModalComp>
            </div>
          ) : (
            <></>
          )}
          
        </>
      
    </div>
    </>
  );
}
