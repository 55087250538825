import * as XLSX from "xlsx";

export function exportExcel(name, roadName, snippet, state) {
  const { dadosTabelaDeReferencia, distance } = state;
  console.log("dadosTabelaDeReferencia", dadosTabelaDeReferencia)
  const { segmentos } = state;
  const excludedTitles = [
    "Imagem de referência",
    "id_section",
    "centre_0",
    "centre_20",
    "centre_40",
    "centre_60",
    "centre_80"
  ];
  let arrayDados = [];
  const rows = [];
  const data = new Date();
  let titulos = [];
  if (dadosTabelaDeReferencia) {
    dadosTabelaDeReferencia.forEach((item) => {
      Object.keys(item).forEach((propriedade) => {
        if (propriedade === "centre - 80" && item["centre - 80"] !== 0) {
          arrayDados.push(item);
        }
      });
    });
  }
  for (let i = 0; i < Object.keys(segmentos).length; i++) {
    let row = {};
    let itensIncompletos = []
    let isCompleted = true;
    row["Nome do codificador"] = name;
    row["Data da codificação"] = data.toLocaleDateString("pt-BR", {
      timeZone: "UTC",
    });
    row["Data de coleta de imagens em campo"] = "";
    Object.keys(segmentos[i]).forEach((item) => {
      if (!segmentos[i][item].selecionado.code) {
        isCompleted = false;
      }
      else if(segmentos[i][item].title === "centre_0"){
        const imageReferenceArray = segmentos[i][item].selecionado.code.split("/");
        if( imageReferenceArray[imageReferenceArray.length - 1].includes('_')){
          const imageReferenceArrayClear =  imageReferenceArray[imageReferenceArray.length - 1].split('_');
          const extension = imageReferenceArrayClear[1].split('.');
          const imageName = `${imageReferenceArrayClear[0]}.${extension[1]}`
          row["Referência da imagem"] = imageName
        }else{
          row["Referência da imagem"] = imageReferenceArray[imageReferenceArray.length - 1]

        }
      }
    });
    row["Nome da via"] = roadName;
    row["Trecho"] = dadosTabelaDeReferencia[i]["Road Name"];
    row["Distância"] = (i > 0?((i*0.1)+Number.parseFloat(distance)):Number.parseFloat(distance));
    row["Extensão do segmento"] = 0.1;
    Object.keys(segmentos[i]).forEach((item) => {

      if (!segmentos[i][item].selecionado.code) {
        isCompleted = false;
      }

      if (!excludedTitles.includes(segmentos[i][item].title))
        row[segmentos[i][item].title] = segmentos[i][item].selecionado.code;
    });
    if (isCompleted) {
      rows.push(reorder(row));
    }
  }

  if (rows.length !== 0) {
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    const colWidths = titulos.map(() => ({wch: 8 }));
    ws['!cols'] = colWidths;
  
    Object.keys(rows[0]).forEach((item, index) => {
      titulos.push(item);
    });
    XLSX.utils.book_append_sheet(wb, ws, "irap");
    XLSX.utils.sheet_add_aoa(ws, [titulos]);
    XLSX.writeFile(
      wb,
      `${roadName}-${data.toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      })}` + ".xlsx", {cellStyles: true}
    );
  }
}

  function reorder(row){
    const itemOrder = ["Nome do codificador", "Data da codificação", "Data de coleta de imagens em campo", "Referência da imagem", "Nome da via",
  "Trecho", "Distância", "Extensão do segmento", "Latitude", "Longitude", "Ponto de referência", "Comentários", "Etiqueta de pista", "Custo dos melhoramentos",
  "Fluxo observado de motocicletas", "Fluxo observado de bicicletas", "Fluxo observado de pedestres atravessando a rodovia", "Fluxo observado de pedestres ao longo da via - lado do condutor",
  "Fluxo observado de pedestres ao longo da via - lado do passageiro", "Uso do solo - lado do condutor", "Uso do solo - lado do passageiro",
  "Tipo de área", "Limite de velocidade", "Limite de velocidade para motocicletas", "Limite de velocidade para caminhões", "Diferencial de velocidades",
  "Tipo de canteiro central", "Sonorizadores ao longo do eixo da pista", "Severidade lateral - distância do lado do condutor", "Severidade lateral - objeto do lado do condutor",
  "Severidade lateral - distância do lado do passageiro", "Severidade lateral - objeto do lado do passageiro", "Sonorizadores ao longo do acostamento", "Largura do acostamento pavimentado - lado do condutor",
  "Largura do acostamento pavimentado - lado do passageiro", "Tipo de interseção", "Canalização da intersecção", "Volume de tráfego na via transversal", "Qualidade da intersecção",
  "Pontos de acesso a propriedades", "Número de faixas", "Largura da faixa", "Curvatura", "Qualidade da curva", "Inclinação", "Condição da rodovia", "Resistência ao deslizamento/aderência",
  "Delineamento", "Iluminação pública da via", "Infraestrutura para travessia de pedestres - na rodovia de inspeção", "Qualidade da travessia de pedestres", "Infraestrutura para travessia de pedestres - na via transversal",
  "Barreiras para pedestre", "Gestão de velocidade/redutor de velocidade", "Estacionamento para veículos", "Calçada ou caminho informal - do lado do condutor", "Calçada ou caminho informal - do lado do passageiro",
  "Rua lateral", "Infraestrutura para veículos motorizados de duas rodas", "Infraestrutura para bicicletas", "Obras viárias", "Distância de visibilidade", "Fluxo de veículos (VMDA)",
  "Percentagem (%) de motocicletas", "Fluxo de pedestres atravessando a via em horário de pico", "Fluxo de pedestres na hora de pico ao longo da rodovia do lado do condutor",
  "Fluxo de pedestres na hora de pico ao longo da rodovia do lado do passageiro", "Fluxo de bicicletas no horário de pico", "Velocidade operacional (percentil 85)", "Velocidade operacional (média)",
  "Vias que os veículos podem ler", "Metas de política de Classificação por Estrelas para carros", "Metas de política de Classificação por Estrelas para motocicletas", "Metas de política de Classificação por Estrelas para pedestres",
  "Metas de política de Classificação por Estrelas para bicicletas", "Multiplicador do número anual de fatalidades", "Advertência de zona escolar", "Supervisor de travessia em zona escolar"
  ]


const rowReorder = []
itemOrder.forEach(item => {
  const search = Object.keys(row).find(i => i === item)
  if(!search){
    console.log(item)
    rowReorder[item] = ""
  }else{
    rowReorder[item]= row[search]
  }
})

return rowReorder
  }

export const checkMissingSections = (segmentos) =>{
  const trechosIncompletos = [];
  Object.keys(segmentos).forEach((i) => {
    let itensFaltantes = [];
    Object.keys(segmentos[i]).forEach((j) => {
      if (segmentos[i][j].selecionado.code === null) {
        itensFaltantes.push(segmentos[i][j].title);
      }
    });
    if (itensFaltantes.length) {
      trechosIncompletos.push({ trecho: parseInt(i) + 1, faltantes: itensFaltantes });
    }
  });

  return trechosIncompletos
}
