import { useState, useEffect } from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import { List, Box, Paper, ListSubheader, Typography } from "@mui/material";
// import itemImg1 from '../../assets/Etiqueta_de_pista.png'
// import itemImg2 from '../../assets/Fluxo_observado.png'
// import itemImg3 from '../../assets/Velocidade.png'
// import itemImg4 from '../../assets/Diferencial _de_velocidade.png'
// import itemImg5 from '../../assets/Numero_de_faixas.png'
// import itemImg6 from '../../assets/Numero_de_faixas.png'
// import itemImg7 from '../../assets/Largura_da_faixa.png'
// import itemImg8 from '../../assets/Curvatura.png'
// import itemImg9 from '../../assets/Qualidade_da_curva.png'
// import itemImg10 from '../../assets/Custo_de_melhorias.png'
// import itemImg11 from '../../assets/Tipo_de_canteiro_central.png'
// import itemImg12 from '../../assets/Tipo_de_canteiro_central2.png'
// import itemImg13 from '../../assets/Tipo_de_canteiro_central3.png'
// import itemImg14 from '../../assets/Tipo_de_canteiro_central4.png'
// import itemImg15 from '../../assets/Tipo_de_canteiro_central5.png'
// import itemImg16 from '../../assets/Tipo_de_canteiro_central6.png'
// import itemImg17 from '../../assets/Tipo_de_canteiro_central7.png'
// import itemImg18 from '../../assets/Resistencia_ao_deslizameneto.png'
// import itemImg19 from '../../assets/Resistencia_ao_deslizameneto2.png'
// import itemImg20 from '../../assets/Condicao_da_via.png'
// import itemImg21 from '../../assets/Estacionamento_de_veiculos.png'
// import itemImg22 from '../../assets/Inclinacao.png'
// import itemImg23 from '../../assets/Obras_viarias.png'
// import itemImg24 from '../../assets/Distancia_de_visibilidade.png'
// import itemImg25 from '../../assets/Delineamento.png'
// import itemImg26 from '../../assets/Iluminacao_publica.png'
// import itemImg27 from '../../assets/Rua_lateral.png'
// import itemImg28 from '../../assets/Sonorizadores_ao_longo_do_eixo_da_pista.png'
// import itemImg29 from '../../assets/Severidade_lateral–distancia_e_objeto.png'
// import itemImg30 from '../../assets/Severidade_lateral–distancia_e_objeto2.png'
// import itemImg31 from '../../assets/Severidade_lateral–distancia_e_objeto3.png'
// import itemImg32 from '../../assets/Severidade_lateral–distancia_e_objeto4.png'
// import itemImg33 from '../../assets/Severidade_lateral–distancia_e_objeto5.png'
// import itemImg34 from '../../assets/Severidade_lateral–distancia_e_objeto6.png'
// import itemImg35 from '../../assets/Severidade_lateral–distancia_e_objeto7.png'
// import itemImg36 from '../../assets/Severidade_lateral–distancia_e_objeto8.png'
// import itemImg37 from '../../assets/Sonorizadores_ao_longo_do_acostamento.png'
// import itemImg38 from '../../assets/acostamento_pavimentado.png'
// import itemImg39 from '../../assets/Intersecoes.png'
// import itemImg40 from '../../assets/Intersecoes2.png'
// import itemImg41 from '../../assets/Intersecoes3.png'
// import itemImg42 from '../../assets/Intersecoes4.png'
// import itemImg43 from '../../assets/Intersecoes5.png'
// import itemImg44 from '../../assets/Intersecoes6.png'
// import itemImg45 from '../../assets/Canalizacao_da_intersecao.png'
// import itemImg46 from '../../assets/Ponto_de_acesso_a_propriedade.png'
// import itemImg47 from '../../assets/Volume_de_trafego_na_transversal.png'
// import itemImg48 from '../../assets/Volume_de_trafego_na_transversal2.png'
// import itemImg49 from '../../assets/Volume_de_trafego_na_transversal3.png'
// import itemImg50 from '../../assets/Uso_do_solo.png'
// import itemImg51 from '../../assets/Tipo_de_area.png'
// import itemImg52 from '../../assets/Instalacao_para_travessia_de_pedestre.png'
// import itemImg53 from '../../assets/Instalacao_para_travessia_de_pedestre2.png'
// import itemImg54 from '../../assets/Instalacao_para_travessia_de_pedestre3.png'
// import itemImg55 from '../../assets/Instalacao_para_travessia_de_pedestre4.png'
// import itemImg56 from '../../assets/Qualidade_da_travessia_para_pedestre.png'
// import itemImg57 from '../../assets/Barreira_de_pedestre.png'
// import itemImg58 from '../../assets/Calcada_ou_caminho_informal.png'
// import itemImg59 from '../../assets/Calcada_ou_caminho_informal2.png'
// import itemImg60 from '../../assets/Calcada_ou_caminho_informal3.png'
// import itemImg61 from '../../assets/Instalacoes_para_motociclistas.png'
// import itemImg62 from '../../assets/Instalacoes_para_motociclistas2.png'
// import itemImg63 from '../../assets/Instalacoes_bicicletas.png'
// import itemImg64 from '../../assets/Instalacoes_bicicletas2.png'
// import itemImg65 from '../../assets/Aviso_de_zona_escolar.png'
// import itemImg66 from '../../assets/Supervisor_de_zona_escolar.png'
// import itemImg67 from '../../assets/Qualidade_da_intersecao.png'
import ManualItem from "../../components/ManualItem/ManualItem";

const Manual = () => {
    // const labels = [{text:"Etiqueta de pista" , img:[itemImg1]},
    // {text:"Fluxo Observado" , img:[itemImg2]},{text:"Velocidade" , img:[itemImg3]},{text: "Diferencial de velocidade" , img:[itemImg4]},
    // {text:"Número de faixas" , img:[itemImg5, itemImg6]},{text:"Largura da faixa" , img:[itemImg7]},{text:"Curvatura" , img:[itemImg8]},
    // {text:"Qualidade da curva" , img:[itemImg9]},{text:"Custo de melhorias" , img:[itemImg10]},{text:"Tipo de canteiro central" , img:[itemImg11,itemImg12,itemImg13,itemImg14,itemImg15,itemImg16,itemImg17]},
    // {text:"Resistência ao deslizamento/ aderência da pista" , img:[itemImg18, itemImg19]},{text:"Condição da via" , img:[itemImg20]},
    // {text:"Estacionamento de veículos" , img:[itemImg21]},{text:"Inclinação" , img:[itemImg22]},{text:"Obras viárias" , img:[itemImg23]},
    // {text:"Distância de visibilidade" , img:[itemImg24]},{text:"Delineamento" , img:[itemImg25]},{text:"Iluminação pública" , img:[itemImg26]},
    // {text:"Rua lateral" , img:[itemImg27]},{text:"Sonorizadores ao longo do eixo da pista" , img:[itemImg28]},{text:"Severidade lateral – distância e objeto" , img:[itemImg29, itemImg30, itemImg31, itemImg32, itemImg33, itemImg34, itemImg35, itemImg36]},
    // {text:"Sonorizadores ao longo do acostamento" , img:[ itemImg37]},{text:"Acostamento pavimentado" , img:[itemImg38]},{text:"Interseções" , img:[ itemImg39, itemImg40, itemImg41, itemImg42, itemImg43, itemImg44]},{text:"Qualidade da interseção" , img:[itemImg67]},
    // {text:"Canalização da interseção" , img:[itemImg45]},{text: "Ponto de acesso a propriedade" , img:[itemImg46]},{text: "Volume de tráfego na transversal" , img:[itemImg47,itemImg48,itemImg49]},
    // {text:"Uso do solo" , img:[itemImg50]},{text:"Tipo de área" , img:[itemImg51]},{text:"Instalação para travessia de pedestre" , img:[itemImg52,itemImg53,itemImg54,itemImg55]},{text:"Qualidade da travessia para pedestre" , img:[itemImg56]},
    // {text:"Barreira de pedestre" , img:[itemImg57]},{text:"Calçada ou caminho informal" , img:[itemImg58,itemImg59,itemImg60]},{text:"Instalações para motociclistas" , img:[itemImg61,itemImg62]},{text:"Instalações para bicicletas" , img:[itemImg63,itemImg64]},
    // {text:"Aviso de zona escolar" , img:[itemImg65]},{text:"Supervisor de zona escolar" , img:[itemImg66]}

// ]
  return (
    <>
      <header>
        <LateralMenu isHome={false}></LateralMenu>
      </header>
      <main>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#e7eaef",
          }}
        >
          <Typography variant="h1">
            Manual do codificador
          </Typography>
          {/* {
            labels.map((item, index)=> {
                return (
                    <ManualItem
                    text={item.text}
                    images={item.img}
                    />
                )
            })
          } */}
   
        </Box>
      </main>
    </>
  );
};

export default Manual;
