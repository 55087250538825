import React, { useState } from "react";
import { Typography, ListItemText, ListItemButton, IconButton, Box, Button } from "@mui/material";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import { formState } from "../../store/reducers/data";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHeader } from "../../store/reducers/dadosHeader";
import {
  saveChange,
  setAllSegments,
  setImagens,
} from "../../store/reducers/dadosSegmentos";
import cloneDeep from "lodash.clonedeep";
import { ModalComp } from "../Modal/Modal";
export function CodificationItem({
  codificationTitle,
  lastSectionName,
  lastDateAlteretion,
  hasImgs,
  idHeader,
  item,
}) {
 
  const navegate = useNavigate();
  const dispacth = useDispatch();
  const [openModal, setOpenModal] = useState(false)
  const [hashDeleted, setHasDeleted] = useState(false)
  const onCLickDeleteButtonHendler = async () =>{
    await pesquisaApi.deleteHeader(idHeader)
    setHasDeleted(true)
  }
  const handleClose = () => {
    setOpenModal(false);
  };
  const onClickHeaderHandler = async () => {
    const dataPayload = {
      dadosTabelaDeReferencia: JSON.parse(item.data_reference_table),
      idHeader: idHeader,
      RoadName: item.road_name,
      snippet: item.section_name,
      distance: item.distance,
    };
    dispacth(setHeader(dataPayload));
    if(hasImgs){
      const res = await pesquisaApi.getSections(idHeader);
        let arrayState = Array(res.data.length)
          .fill()
          .map(() => cloneDeep(formState));
        let data = await res.data;
        console.log("data aqui", data)
        data.sort((a, b) => a.id_section - b.id_section);
        let imagens = []
        data.forEach((obj, i) => {
        
          for (let _data in data[i]) {
            if (
              _data === "centre_0" ||
              _data === "centre_20" ||
              _data === "centre_40" ||
              _data === "centre_60" ||
              _data === "centre_80"
            ) {
            imagens.push(data[i][_data])
          
            } 
          }
          //   setImagesForState((prevLabels) => [...prevLabels, label]);
        });
        dispacth(setImagens({imagens: imagens}))
    
        arrayState.forEach((item, i) => {
          // console.log(typeof data[i]);
          item.forEach((parametro, j) => {
            // console.log(parametro);
            for (let datas in data[i]) {
              if (datas === parametro.varName) {
                parametro.selecionado.code = data[i][datas];
                parametro.label.forEach((option) => {
                  if (option.code === data[i][datas]) {
                    parametro.selecionado.title = option.title;
                  }
                });
                arrayState[i][j].selecionado.code = data[i][datas];
              }
            }
          });
        });
        console.log("arrayState", arrayState)
            dispacth(saveChange());
      dispacth(
        setAllSegments({ segmentos: arrayState, length: arrayState.length })
      );
      navegate('/search')
    }else{
      navegate("/uploadImages", {state: {reload: false}})
    }
   
  };

  return (
    <>
    <ModalComp
    open={openModal}
    title={"Deletar codificação?"} 
    subtitle={"Ao deletar a codificação, ela será perdida permanentemente. Esta ação é irreversível e todos os dados associados não poderão ser recuperados. Certifique-se de que realmente deseja prosseguir antes de confirmar a exclusão."}

    >
      <Button
                  onClick={() => {
                    onCLickDeleteButtonHendler()
                    setOpenModal(!openModal);
                  }}
                >
                  Sim
                </Button>
                <Button onClick={handleClose}>Não</Button>
    </ModalComp>
    {hashDeleted? (<></>): (
      
    <Box sx={{display: "flex", borderBottom: "1px solid #808080", height: "14vh"}}>

      <ListItemButton
        alignItems="flex-start"
        onClick={onClickHeaderHandler}
      >
        <ListItemText
          primary={codificationTitle}
          secondary={
            <div>
              <Box>
                nome da via: {codificationTitle}, trecho: {lastSectionName}
              </Box>
              <Box>
                Data da ultima alteração: {lastDateAlteretion}
              </Box>
            </div>
          }
        ></ListItemText>
      </ListItemButton>
      <Box height={"100%"} sx={{display: "flex", justifyContent:"center", alignItems:"center"}}>
      <IconButton onClick={()=> setOpenModal(true)}>
                <DeleteIcon fontSize="large"></DeleteIcon>
            </IconButton>
      </Box>
    </Box>
    )}
    
    </>
  );
}
