import { useState, useEffect } from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import { List, Box, Paper, ListSubheader } from "@mui/material";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import { resetState } from "../../store";
import { UserItem } from "../../components/UserItem/UserItem";
import { Loading } from "../../components/Loading";
const Codificadores = () => {
  const [codificadores, setCodificadores] = useState([]);
  const [openBackdrop, setOpenBackDrop] = useState(true)
  useEffect(() => {
    let axiosData = async () => {
      const res = await pesquisaApi.getUsers();
      console.log("heardes", res);
      setCodificadores(res.data.results);
      setOpenBackDrop(false)
    };
    resetState();
    axiosData();
  }, []);
  return (
    <>
        {openBackdrop && <Loading/>}
      <header>
        <LateralMenu isHome={false}></LateralMenu>
      </header>
      <main>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#e7eaef",
          }}
        >
          

          <Paper elevation={3}>
            <List
              sx={{
                width: "100%",
                minWidth: 1000,
                overflow: "auto",
                maxHeight: "65vh",
                padding: 0,
              }}
            >
              <ListSubheader sx={{ marginBottom: "-30px", marginTop: "" }}>
                <h2>Codificadores</h2>
              </ListSubheader>
              {codificadores.map((item) => {
                  if(item.username != "admin"){
                    console.log("id", item.id)
                    return <UserItem
                     encoderName={item.username}
                     id={item.id}
                      key={item.id}
                    />
                }else{
                    console.log("user", item.username)
                }
              })}
            </List>
          </Paper>
        </Box>
      </main>
    </>
  );
};

export default Codificadores;
