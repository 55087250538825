import LateralMenu from '../../components/LateralMenu/LateralMenu'
import DragAndDrop from '../../components/DragAndDrop2/DragAndDrop'
import { Box, Container } from '@mui/material'
import ButtomComp from "../../components/Button/Button"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pesquisaApi } from '../../services/apiPesquisa.servise'
import cloneDeep from 'lodash.clonedeep'
import { formState } from '../../store/reducers/data'
import { saveChange, setAllSegments, setImagens } from '../../store/reducers/dadosSegmentos'

export default function UploadImages(){
    const stateLeagth = useSelector(state => state.dadosSegmentos.length)
    const idHeader = useSelector(state => state.dadosHeader.idHeader)
    const navigate = useNavigate()
    const dispacth = useDispatch()

    
    const onClickHendler = async () =>{
        const res = await pesquisaApi.getSections(idHeader);
        let arrayState = Array(res.data.length)
        .fill()
        .map(() => cloneDeep(formState));
        let data = await res.data;
        data.sort((a, b) => a.id_section - b.id_section);
        let label = [];
        let imageNames = ["0 m", "20 m", "40m", "60 m", "80 m"];
        let imagens = []
        data.forEach((obj, i) => {
          let aux = [];
          for (let _data in data[i]) {
            if (
                _data === "centre_0" ||
                _data === "centre_20" ||
                _data === "centre_40" ||
                _data === "centre_60" ||
              _data === "centre_80"
            ) {
                imagens.push(data[i][_data])
               
        } 
    }
        });
        dispacth(setImagens({imagens: imagens}))
        
        arrayState.forEach((item, i) => {
            // console.log(typeof data[i]);
            item.forEach((parametro, j) => {
                // console.log(parametro);
                for (let datas in data[i]) {
              if (datas === parametro.varName) {
                parametro.selecionado.code = data[i][datas];
                parametro.label.forEach((option) => {
                    if (option.code === data[i][datas]) {
                        parametro.selecionado.title = option.title;
                    }
                });
                arrayState[i][j].selecionado.code = data[i][datas];
            }
        }
    });
});
dispacth(saveChange());
dispacth(
    setAllSegments({ segmentos: arrayState, length: arrayState.length })
    );
    await pesquisaApi.uploadHeader({hasImgs: true}, idHeader)
    navigate("/search", {
        state: {
            
            roadName: "tesate",
            snippet: "teste",
        },
    })
    }
    

    return(
        <Container sx={{height: '100vh', width: "100%"}}>     
            <LateralMenu>
            </LateralMenu>
            <Container sx={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>

            <DragAndDrop/>
            <Box sx={{width: "90%", display: "flex", justifyContent: "center"}}>

            <ButtomComp disabled={stateLeagth <= 0} onClick={onClickHendler}>Iniciar codificação</ButtomComp>
            </Box>
            </Container>
        </Container>
    )
}  