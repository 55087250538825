import { useState, useEffect } from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import { List, Box, Paper, ListSubheader } from "@mui/material";
import { CodificationItem } from "../../components/CodificationItem/CodificationItem";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import { resetState } from "../../store";
import { useLocation } from "react-router-dom";
import { Loading } from "../../components/Loading";
const Home = () => {
  const location = useLocation()
  const anotherUserId = location.state && location.state.anotherUserId ? location.state.anotherUserId : null;
  const [headers, setHeaders] = useState([]);
  const [openBackdrop, setOpenBackDrop] = useState(true)
  useEffect(() => {
    let axiosData = async () => {
      let res 
      if(anotherUserId){
        res = await pesquisaApi.getHeadersAnotherUser(anotherUserId) 
      }else{
        res = await pesquisaApi.getHeaders();
        console.log(res)
      }
      setHeaders(res.data);
      setOpenBackDrop(false)
    };
    resetState();
    axiosData();
  }, []);
  return (
    <>
    {openBackdrop && <Loading/>}
      <header>
        <LateralMenu isHome={true}></LateralMenu>
      </header>
      <main>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#e7eaef",
          }}
        >
          

          <Paper elevation={3}>
            <List
              sx={{
                width: "100%",
                minWidth: 1000,
                overflow: "auto",
                maxHeight: "65vh",
                padding: 0,
              }}
            >
              <ListSubheader sx={{ marginBottom: "-30px", marginTop: "" }}>
                <h2>Codificações</h2>
              </ListSubheader>
              {headers
    .slice() 
    .sort((a, b) => b.id_header - a.id_header) 
    .map((item) => (
        <CodificationItem
            lastDateAlteretion={item.coding_date}
            codificationTitle={item.road_name}
            lastSectionName={item.section_name}
            idHeader={item.id_header}
            item={item}
            hasImgs={item.hasImgs}
            key={item.id_header}
        ></CodificationItem>
    ))
}

            </List>
          </Paper>
        </Box>
      </main>
    </>
  );
};

export default Home;
