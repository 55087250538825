import { createContext, useReducer } from "react";
import React from "react";
export const ImageView = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { hasFlex: !state.hasFlex };
  }
};

export const ImageViewProvider = ({ children }) => {
  const [imageView, dispatchImageView] = useReducer(reducer, {
    hasFlex: false,
  });

  return (
    <ImageView.Provider value={{ imageView, dispatchImageView }}>
      {children}
    </ImageView.Provider>
  );
};
