import { configureStore, combineReducers} from "@reduxjs/toolkit";
import dadosSegmentosSlice, {
  reset as resetDadosSegmentos,
} from "./reducers/dadosSegmentos";
import dadosHeaderSlice, {
  reset as resetDadosHeader,
} from "./reducers/dadosHeader";
import userSlice from "./reducers/user"
import storage from "redux-persist/lib/storage";
import {persistReducer} from 'redux-persist'

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
}

const reducer = combineReducers({
  dadosSegmentos: dadosSegmentosSlice,
    dadosHeader: dadosHeaderSlice,
    user: userSlice}
    )

    const persistedReducer = persistReducer(persistConfig, reducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export const resetState = () => {
  store.dispatch(resetDadosSegmentos());
  store.dispatch(resetDadosHeader());
};
