import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    expiry: null,
    username: ""
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      if (payload.user)
        state.user = payload.user;

    },

  },
});
export const { setUser } = userSlice.actions;
export default userSlice.reducer;
