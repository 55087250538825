import styles from "../../styles/Forms.module.css";
import React from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import Tabs from "../../components/Tabs/Tabs";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { checkMissingSections, exportExcel } from "../../utils/excel";
import ButtonComp from "../../components/Button/Button";
import { authService } from "../../services/auth.service";
import { ImageView } from "../../contexts/imageView";
import { ModalComp } from "../../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import cloneDeep from "lodash.clonedeep";
import { saveChange, setAllSegments, setDuplicateImagesId, setImagens } from "../../store/reducers/dadosSegmentos";
import { useLocation } from "react-router-dom";
import { formState } from "../../store/reducers/data";
import { Typography } from "@mui/material";
export default function Search() {
  const user = authService.getLoggedUsername();
  const slices = useSelector((state) => state);
  const state = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const roadName = state.RoadName;
  const snippet = state.snippet;
  const [dataImport, setDataImport] = useState();
  const images = "";
  const location = useLocation()
  const [openModal, setOpenModal] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [openModalRepetidas, setOpenModalRepetidas] = useState(false);
  const [itensFaltando, setItensFaltando] = useState([]);
  const { imageView, dispatchImageView } = useContext(ImageView);
  const dispacth = useDispatch()
  const handleClose = () => {
    setOpenModal(false);
    setOpenModalSave(false);
  };

  

  function handleExportExcel() {
    const faltantes = checkMissingSections(state.segmentos)
    console.log("os faltantes", faltantes, faltantes.length)
    if (faltantes.length != 0) {
      setOpenModal(true);
      setItensFaltando(faltantes)
    } else {
      console.log("state", state);

      exportExcel(user, roadName, snippet, state);
    }
  }


useEffect(()=>{
  const getSearch = async() => {
    const res = await pesquisaApi.getSections(state.idHeader);
          let arrayState = Array(res.data.length)
            .fill()
            .map(() => cloneDeep(formState));
          let data = await res.data;
          data.sort((a, b) => a.id_section - b.id_section);
          let imagens = []
          let imagensRepetidas = []
          const imgRefServidor = []
          data.forEach((obj, i) => {
          
            for (let _data in data[i]) {
              if (
                _data === "centre_0" ||
                _data === "centre_20" ||
                _data === "centre_40" ||
                _data === "centre_60" ||
                _data === "centre_80"
              ) {
              imagens.push(data[i][_data])
            
              } 
            }
            let imageName
            if(data[i]["image_reference"].includes('_')){
              const imageReferenceArrayClear =  data[i]["image_reference"].split('_');
              const extension = imageReferenceArrayClear[1].split('.');
              imageName = `${imageReferenceArrayClear[0]}.${extension[1]}`
            }else{
              imageName = data[i]["image_reference"]
              console.log("entrou no else")
            }
            if(imgRefServidor.includes(imageName)){
              imagensRepetidas.push(data[i]["id_section"])
            }else{
              imgRefServidor.push(imageName)
              console.log("data[i]", data[i])
            }
            //   setImagesForState((prevLabels) => [...prevLabels, label]);
          });
          dispacth(setImagens({imagens: imagens}))
          dispacth(setDuplicateImagesId(imagensRepetidas))
          if(imagensRepetidas.length){
            setOpenModalRepetidas(true)
          }
          console.log("iamgens repetidas", imagensRepetidas, imgRefServidor)
          arrayState.forEach((item, i) => {
            // console.log(typeof data[i]);
            item.forEach((parametro, j) => {
              // console.log(parametro);
              for (let datas in data[i]) {
                if (datas === parametro.varName) {
                  parametro.selecionado.code = data[i][datas];
                  parametro.label.forEach((option) => {
                    if (option.code === data[i][datas]) {
                      parametro.selecionado.title = option.title;
                    }
                  });
                  arrayState[i][j].selecionado.code = data[i][datas];
                }
              }
            });
          });
          console.log("arrayState", arrayState)
              dispacth(saveChange());
        dispacth(
          setAllSegments({ segmentos: arrayState, length: arrayState.length })
        );
  }

getSearch()
}, [])

  useEffect(() => {
    console.log(imageView.hasFlex);
  }, [imageView]);
  return (
    <>
      <LateralMenu isForm={true}></LateralMenu>
      <main>
          <>
          <ModalComp
          open={openModalRepetidas}
          title={"O segmento atual contém trechos repetidos."}
          subtitle={"Antes de continuar, por favor, entre em contato com nossa equipe para que os trechos repetidos possam ser removidos."}
          >
            <Button onClick={()=>{
              setOpenModalRepetidas(false)
            }}>Ok</Button>
          </ModalComp>
            {itensFaltando.length != 0 ? (
              <div>
                <ModalComp
                  title={"A codificação do segmento atual está imcompleta."}
                  subtitle={"Os seguintes trechos estão incompletos:"}
                  open={openModal}
                >
                  <Box>
          {itensFaltando.map((item, index) => (
            <div key={index}>
              <Typography variant="h6">Trecho: {item.trecho}</Typography>
              <Typography>Itens:</Typography>
              {item.faltantes.map((faltante, idx) => (
                <Typography key={idx} color={"red"}>{faltante}</Typography>
              ))}
            </div>
          ))}
          <Typography >Deseja continuar mesmo assim?</Typography>
          <Button onClick={() => {
            exportExcel(user, roadName, snippet, state);
            setOpenModal(false)
            }}>Sim</Button>
          <Button onClick={() => setOpenModal(false)}>Não</Button>
        </Box>
                </ModalComp>
              </div>
            ) : (
              <></>
            )}
            {state.isSave === false ? (
              <div>
                <ModalComp
                  title={"As seções não foram salvas"}
                  subtitle={""}
                  open={openModalSave}
                >
                  {itensFaltando.map((item, i) => (
                    <p key={i} style={{ color: "#FF0000" }}>
                      {item}
                    </p>
                  ))}
                  <p id="parent-modal-description">
                    Por favor, para prosseguir uma a exportação de dados, salve
                    as seçõas.
                  </p>

                  <Button onClick={handleClose}>Ok</Button>
                </ModalComp>
              </div>
            ) : (
              <></>
            )}
            <div className={imageView.hasFlex ? styles.imageViewFlex : ""}>
              <DragAndDrop
                data={dataImport}
                images={images}
                hasNew={false}
              ></DragAndDrop>

              <div>
                <Tabs
                  data={dataImport}
                  dadosDoCod={{
                    nome: user,
                    roadName: roadName,
                    snippet: snippet,
                  }}
                ></Tabs>

                <Box
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <ButtonComp
                    sx={{ marginLeft: "10px" }}
                    onClick={() => {
                      let latitude = ''
                      let longitude = ''
                     
                      Object.keys(state.segmentos[state.paginaAtual - 1]).forEach((item) => {
                        if (state.segmentos[state.paginaAtual - 1][item].title === "Latitude") {
                          latitude = state.segmentos[state.paginaAtual - 1][item].selecionado.code;
                        }
                        else if (state.segmentos[state.paginaAtual - 1][item].title === "Longitude") {
                          
                          longitude = state.segmentos[state.paginaAtual - 1][item].selecionado.code;
                        }
                      })
                      window.open(`https://www.google.com/maps?q=${latitude},${longitude}`)
                    }}
                  >
                    Abrir no mapa
                  </ButtonComp>
                  <ButtonComp
                    sx={{ marginLeft: "10px" }}
                    onClick={() => {
                      console.log("save", state.isSave);
                      if (state.isSave === true) {
                        handleExportExcel();
                      } else {
                        setOpenModalSave(true);
                      }
                    }}
                  >
                    Exportar dados
                  </ButtonComp>
                </Box>
              </div>
            </div>
          </>
        
      </main>
    </>
  );
}
