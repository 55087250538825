export const informacoesExtras = [
  {
    varName: "centre_0",
    label: [],
    title: "centre_0",
    selecionado: { title: "centre_0", code: null },
  },
  {
    varName: "centre_20",
    label: [],
    title: "centre_20",
    selecionado: { title: "centre_20", code: null },
  },
  {
    varName: "centre_40",
    label: [],
    title: "centre_40",
    selecionado: { title: "centre_40", code: null },
  },
  {
    varName: "centre_60",
    label: [],
    title: "centre_60",
    selecionado: { title: "centre_60", code: null },
  },
  {
    varName: "centre_80",
    label: [],
    title: "centre_80",
    selecionado: { title: "centre_80", code: null },
  },
  {
    varName: "latitude",
    label: [],
    title: "Latitude",
    selecionado: { title: "Latitude", code: null },
  },  
  {
    varName: "longitude",
    label: [],
    title: "Longitude",
    selecionado: { title: "Longitude", code: null },
  },
];

export const fluxoObservado = [
  {
    varName: "id_section",
    label: [],
    title: "id_section",
    selecionado: { title: "id_section", code: null },
  },
  {
    varName: "track_label",
    title: "Etiqueta de pista",
    label: [
      { title: "Pista A de uma pista dupla", code: 1 },
      { title: "Pista B de uma pista dupla", code: 2 },
      { title: "Pista simples", code: 3 },
      { title: "Pista A de uma pista de motocicletas ", code: 4 },
      { title: "Pista B de uma pista de motocicletas ", code: 5 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "observed_motorcycle_flow",
    title: "Fluxo observado de motocicletas",
    label: [
      { title: "8+", code: 6 },
      { title: "6 a 7", code: 5 },
      { title: "4 a 5", code: 4 },
      { title: "2 a 3", code: 3 },
      { title: "1", code: 2 },
      { title: "Nenhuma", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "observed_bicycle_flow",
    title: "Fluxo observado de bicicletas",
    label: [
      { title: "8+", code: 6 },
      { title: "6 a 7", code: 5 },
      { title: "4 a 5", code: 4 },
      { title: "2 a 3", code: 3 },
      { title: "1", code: 2 },
      { title: "Nenhuma", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "observed_pedestrian_crossing_flow",
    title: "Fluxo observado de pedestres atravessando a rodovia",
    label: [
      { title: "8+", code: 6 },
      { title: "6 a 7", code: 5 },
      { title: "4 a 5", code: 4 },
      { title: "2 a 3", code: 3 },
      { title: "1", code: 2 },
      { title: "Nenhum", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "observed_driver_side_pedestrian_flow",
    title: "Fluxo observado de pedestres ao longo da via - lado do condutor",
    label: [
      { title: "8+", code: 6 },
      { title: "6 a 7", code: 5 },
      { title: "4 a 5", code: 4 },
      { title: "2 a 3", code: 3 },
      { title: "1", code: 2 },
      { title: "Nenhum", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "observed_passenger_side_pedestrian_flow",
    title: "Fluxo observado de pedestres ao longo da via - lado do passageiro",
    label: [
      { title: "8+", code: 6 },
      { title: "6 a 7", code: 5 },
      { title: "4 a 5", code: 4 },
      { title: "2 a 3", code: 3 },
      { title: "1", code: 2 },
      { title: "Nenhum", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const velocidade = [
  {
    varName: "speed_limit",
    title: "Limite de velocidade",
    label: [
      { title: "≥150km/h", code: 25 },
      { title: "140km/h", code: 23 },
      { title: "130km/h", code: 21 },
      { title: "120km/h", code: 19 },
      { title: "110km/h", code: 17 },
      { title: "100km/h", code: 15 },
      { title: "90km/h", code: 13 },
      { title: "80km/h", code: 11 },
      { title: "70km/h", code: 9 },
      { title: "60km/h", code: 7 },
      { title: "50km/h", code: 5 },
      { title: "40km/h", code: 3 },
      { title: "≤30km/h", code: 1 },
      { title: "≥90mph", code: 45 },
      { title: "80mph", code: 43 },
      { title: "70mph", code: 41 },
      { title: "60mph", code: 39 },
      { title: "50mph", code: 37 },
      { title: "40mph", code: 35 },
      { title: "30mph", code: 33 },
      { title: "<20mph", code: 31 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "motorcycle_speed_limit",
    title: "Limite de velocidade para motocicletas",
    label: [
      { title: "≥150km/h", code: 25 },
      { title: "140km/h", code: 23 },
      { title: "130km/h", code: 21 },
      { title: "120km/h", code: 19 },
      { title: "110km/h", code: 17 },
      { title: "100km/h", code: 15 },
      { title: "90km/h", code: 13 },
      { title: "80km/h", code: 11 },
      { title: "70km/h", code: 9 },
      { title: "60km/h", code: 7 },
      { title: "50km/h", code: 5 },
      { title: "40km/h", code: 3 },
      { title: "≤30km/h", code: 1 },
      { title: "≥90mph", code: 45 },
      { title: "80mph", code: 43 },
      { title: "70mph", code: 41 },
      { title: "60mph", code: 39 },
      { title: "50mph", code: 37 },
      { title: "40mph", code: 35 },
      { title: "30mph", code: 33 },
      { title: "<20mph", code: 31 },
    ],
    selecionado: { title: "", code: null },
  },

  {
    varName: "truck_speed_limit",
    title: "Limite de velocidade para caminhões",
    label: [
      { title: "≥150km/h", code: 25 },
      { title: "140km/h", code: 23 },
      { title: "130km/h", code: 21 },
      { title: "120km/h", code: 19 },
      { title: "110km/h", code: 17 },
      { title: "100km/h", code: 15 },
      { title: "90km/h", code: 13 },
      { title: "80km/h", code: 11 },
      { title: "70km/h", code: 9 },
      { title: "60km/h", code: 7 },
      { title: "50km/h", code: 5 },
      { title: "40km/h", code: 3 },
      { title: "≤30km/h", code: 1 },
      { title: "≥90mph", code: 45 },
      { title: "80mph", code: 43 },
      { title: "70mph", code: 41 },
      { title: "60mph", code: 39 },
      { title: "50mph", code: 37 },
      { title: "40mph", code: 35 },
      { title: "30mph", code: 33 },
      { title: "<20mph", code: 31 },
    ],
    selecionado: { title: "", code: null },
  },

  {
    varName: "speed_differential",
    title: "Diferencial de velocidades",
    label: [
      { title: "Presente", code: 2 },
      { title: "Não presente", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
 
  {
    varName: "speed_management",
    title: "Gestão de velocidade/redutor de velocidade",
    label: [
      { title: "Presente", code: 2 },
      { title: "Não presente", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const caracteristicasDaVia = [
  {
    varName: "number_of_lanes",
    title: "Número de faixas",
    label: [
      { title: "Quatro ou mais", code: 4 },
      { title: "Três", code: 3 },
      { title: "Três e duas", code: 6 },
      { title: "Duas", code: 2 },
      { title: "Duas e uma", code: 5 },
      { title: "Uma", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "lane_width",
    title: "Largura da faixa",
    label: [
      { title: "Estreita <2.75m", code: 3 },
      { title: "Média 2.75m a <3.25m", code: 2 },
      { title: "Larga ≥3.25m", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "curvature",
    title: "Curvatura",
    label: [
      { title: "Muito fechada", code: 4 },
      { title: "Fechada", code: 3 },
      { title: "Moderada", code: 2 },
      { title: "Reta ou levemente curva", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "curve_quality",
    title: "Qualidade da curva",
    label: [
      { title: "Deficiente", code: 2 },
      { title: "Não se aplica", code: 3 },
      { title: "Adequado", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "improvement_cost",
    title: "Custo dos melhoramentos",
    label: [
      { title: "Alto", code: 3 },
      { title: "Médio", code: 2 },
      { title: "Baixo", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "type_of_median",
    title: "Tipo de canteiro central",
    label: [
      { title: "Linha central divisória", code: 11 },
      { title: "Demarcação central larga de > 0.3m a 1m", code: 14 },
      { title: "Canteiro central fictício de >1m", code: 10 },
      { title: "Faixa central continua para conversões", code: 8 },
      { title: "Postes flexíveis", code: 9 },
      { title: "Largura do canteiro central físico de 0m a <1m", code: 7 },
      { title: "Largura do canteiro central de 1m a <5m", code: 6 },
      { title: "Largura do canteiro central de 5m a <10m", code: 5 },
      { title: "Barreira de segurança - concreto", code: 2 },
      { title: "Barreira de segurança - metal", code: 1 },
      { title: "Barreira de segurança - amigável a motocicleta", code: 12 },
      { title: "Barreira de segurança - cabo de aço", code: 15 },
      { title: "Canteiro central de 10m a <20m", code: 4 },
      { title: "Canteiro central de ≥20m", code: 3 },
      { title: "Mão única", code: 13 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "skid_resistance",
    title: "Resistência ao deslizamento/aderência",
    label: [
      { title: "Não pavimentada - deficiente", code: 5 },
      { title: "Não pavimentada - adequada", code: 4 },
      { title: "Pavimentada - deficiente", code: 3 },
      { title: "Pavimentada - média", code: 2 },
      { title: "Pavimentada - adequada", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "road_condition",
    title: "Condição da rodovia",
    label: [
      { title: "Deficiente", code: 3 },
      { title: "Regular", code: 2 },
      { title: "Boa", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const caracteristicasDaVia2 = [
  {
    varName: "vehicle_parking",
    title: "Estacionamento para veículos",
    label: [
      { title: "Ambos os lados", code: 3 },
      { title: "Um lado", code: 2 },
      { title: "Nenhum", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "inclination",
    title: "Inclinação",
    label: [
      { title: "≥10%", code: 5 },
      { title: "7.5% a <10%", code: 4 },
      { title: "0% a <7.5%", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "road_works",
    title: "Obras viárias",
    label: [
      { title: "Grandes obras na via", code: 3 },
      { title: "Obras viárias menores", code: 2 },
      { title: "Nenhuma obra viária", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "visibility_distance",
    title: "Distância de visibilidade",
    label: [
      { title: "Deficiente", code: 2 },
      { title: "Adequada", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "delineation",
    title: "Delineamento",
    label: [
      { title: "Deficiente", code: 2 },
      { title: "Adequado", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "public_lighting",
    title: "Iluminação pública da via",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "side_street",
    title: "Rua lateral",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "sound_emitters_on_lane",
    title: "Sonorizadores ao longo do eixo da pista",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const margemDaVia = [
  
  {
    varName: "driver_side_lateral_severity_object",
    title: "Severidade lateral - objeto do lado do condutor",
    label: [
      { title: "Penhasco", code: 10 },
      { title: "Arvore de ≥10cm", code: 11 },
      { title: "Sinalização, poste ou coluna rígidos de ≥10cm", code: 12 },
      {
        title: "Barreira de segurança não protegida, sem terminal amortecedor",
        code: 15,
      },
      { title: "Parede vertical agressiva", code: 5 },
      { title: "Talude de corte - risco de capotamento", code: 6 },
      { title: "Valeta profunda de drenagem", code: 8 },
      { title: "Declive", code: 9 },
      { title: "Objeto rígido baixo de ≥20cm de altura", code: 16 },
      { title: "Estrutura ou construção rígidas", code: 13 },
      { title: "Talude de corte - sem risco de capotamento", code: 7 },
      { title: "Estrutura ou construção semirrígidas", code: 14 },
      { title: "Barreira de segurança - concreto", code: 2 },
      { title: "Barreira de segurança - metal", code: 1 },
      { title: "Barreira de segurança - cabo de aço", code: 4 },
      { title: "Barreira de segurança - amigável a motociclistas", code: 3 },
      { title: "Nenhum objeto", code: 17 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "driver_side_lateral_severity_distance",
    title: "Severidade lateral - distância do lado do condutor",
    label: [
      { title: "0 a <1m", code: 1 },
      { title: "1 a <5m", code: 2 },
      { title: "5 a <10m", code: 3 },
      { title: ">10m", code: 4 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "passenger_side_lateral_severity_object",
    title: "Severidade lateral - objeto do lado do passageiro",
    label: [
      { title: "Penhasco", code: 10 },
      { title: "Arvore de ≥10cm", code: 11 },
      { title: "Sinalização, poste ou coluna rígidos de ≥10cm", code: 12 },
      {
        title: "Barreira de segurança não protegida, sem terminal amortecedor",
        code: 15,
      },
      { title: "Parede vertical agressiva", code: 5 },
      { title: "Talude de corte - risco de capotamento", code: 6 },
      { title: "Valeta profunda de drenagem", code: 8 },
      { title: "Declive", code: 9 },
      { title: "Objeto rígido baixo de ≥20cm de altura", code: 16 },
      { title: "Estrutura ou construção rígidas", code: 13 },
      { title: "Talude de corte - sem risco de capotamento", code: 7 },
      { title: "Estrutura ou construção semirrígidas", code: 14 },
      { title: "Barreira de segurança - concreto", code: 2 },
      { title: "Barreira de segurança - metal", code: 1 },
      { title: "Barreira de segurança - cabo de aço", code: 4 },
      { title: "Barreira de segurança - amigável a motociclistas", code: 3 },
      { title: "Nenhum objeto", code: 17 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "passenger_side_lateral_severity_distance",
    title: "Severidade lateral - distância do lado do passageiro",
    label: [
      { title: "0 a <1m", code: 1 },
      { title: "1 a <5m", code: 2 },
      { title: "5 a <10m", code: 3 },
      { title: ">10m", code: 4 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "sound_emitters_on_shoulder",
    title: "Sonorizadores ao longo do acostamento",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "driver_side_paved_shoulder_width",
    title: "Largura do acostamento pavimentado - lado do condutor",
    label: [
      { title: "Nenhum", code: 4 },
      { title: "Estreito 0m a <1m", code: 3 },
      { title: "Médio 1m a <2.4m", code: 2 },
      { title: "Largo ≥2.4m", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "passenger_side_paved_shoulder_width",
    title: "Largura do acostamento pavimentado - lado do passageiro",
    label: [
      { title: "Nenhum", code: 4 },
      { title: "Estreito 0m a <1m", code: 3 },
      { title: "Médio 1m a <2.4m", code: 2 },
      { title: "Largo ≥2.4m", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const intersecoes = [
  {
    varName: "intersection_type",
    title: "Tipo de interseção",
    label: [
      { title: "4+ aproximações", code: 8 },
      { title: "4+ aproximações com faixa protegida para conversões", code: 7 },
      { title: "4+ aproximações com semáforo", code: 10 },
      { title: "3 aproximações", code: 4 },
      { title: "3 aproximações com faixa protegida para conversões", code: 3 },
      { title: "Mini rotatória", code: 17 },
      { title: "3 aproximações com semáforo", code: 6 },
      {
        title:
          "4 aproximações com semáforo e faixa de proteção para conversões",
        code: 9,
      },
      { title: "3 aproximações com faixa protegida para conversões", code: 5 },
      { title: "Rotatória", code: 2 },
      { title: "Cruzamento de ferrovia - passivo", code: 13 },
      { title: "Faixa de convergência / divergência", code: 1 },
      { title: "Cruzamento de ferrovia - ativo", code: 14 },
      {
        title: "Ponto de cruxamento de canteiro central - informal",
        code: 15,
      },
      { title: "Ponto de cruzamento de canteiro central - formal", code: 16 },
      { title: "Nenhum", code: 12 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "intersection_quality",

    title: "Qualidade da intersecção",
    label: [
      { title: "Deficiente", code: 2 },
      { title: "Adequada", code: 1 },
      { title: "Não se aplica", code: 3 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "intersection_channelization",
    title: "Canalização da intersecção",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "property_access_points",
    title: "Pontos de acesso a propriedades",
    label: [
      { title: "Acesso comercial ≥1", code: 1 },
      { title: "Acesso residencial ≥3", code: 2 },
      { title: "Acesso residencial <3", code: 3 },
      { title: "Nenhum", code: 4 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "cross_traffic_volume",
    title: "Volume de tráfego na via transversal",
    label: [
      { title: "≥15,000 veículos", code: 1 },
      { title: "10,000 a 15,000 veículos", code: 2 },
      { title: "5,000 a 10,000 veículos", code: 3 },
      { title: "1,000 a 5,000 veículos", code: 4 },
      { title: "100 a 1,000 veículos", code: 5 },
      { title: "1 a 100 veículos", code: 6 },
      { title: "Não se aplica", code: 7 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const instalacoesUVV = [
  {
    varName: "driver_side_land_use",
    title: "Uso do solo - lado do condutor",
    label: [
      { title: "Educacional", code: 6 },
      { title: "Comercial", code: 4 },
      { title: "Industrial e fabricação", code: 7 },
      { title: "Residencial", code: 3 },
      { title: "Fazenda e agricultura", code: 2 },
      { title: "Áreas não desenvolvidas", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "passenger_side_land_use",
    title: "Uso do solo - lado do passageiro",
    label: [
      { title: "Educacional", code: 6 },
      { title: "Comercial", code: 4 },
      { title: "Industrial e fabricação", code: 7 },
      { title: "Residencial", code: 3 },
      { title: "Fazenda e agricultura", code: 2 },
      { title: "Áreas não desenvolvidas", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "area_type",
    title: "Tipo de área",
    label: [
      { title: "Urbana", code: 2 },
      { title: "Rural", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "pedestrian_crossing_infrastructure_on_inspection_road",

    title: "Infraestrutura para travessia de pedestres - na rodovia de inspeção",
    label: [
      { title: "Nenhuma instalação", code: 7 },
      { title: "Somente refúgio", code: 6 },
      { title: "Faixa de travessia somente", code: 5 },
      { title: "Faixa de travessia elevada", code: 17 },
      { title: "Faixa de travessia com refúgio", code: 4 },
      { title: "Travessia elevada sem sinalização com refúgio", code: 16 },
      { title: "Faixa de travessia elevada", code: 15 },
      { title: "Faixa de travessia elevada com refúgio", code: 14 },
      { title: "Faixa de travessia com semáforo", code: 3 },
      { title: "Semáforo de pedestre com refúgio", code: 2 },
      { title: "Travessia em desnível separada", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "pedestrian_crossing_quality",
    title: "Qualidade da travessia de pedestres",
    label: [
      { title: "Deficiente", code: 2 },
      { title: "Adequada", code: 1 },
      { title: "Não se aplica", code: 3 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "pedestrian_crossing_infrastructure_on_crossroad",
    title: "Infraestrutura para travessia de pedestres - na via transversal",
    label: [
      { title: "Nenhuma instalação", code: 7 },
      { title: "Somente refúgio", code: 6 },
      { title: "Faixa de travessia somente", code: 5 },
      { title: "Faixa de travessia elevada", code: 17 },
      { title: "Faixa de travessia com refúgio", code: 4 },
      { title: "Travessia elevada sem sinalização com refúgio", code: 16 },
      { title: "Faixa de travessia elevada", code: 15 },
      { title: "Faixa de travessia elevada com refúgio", code: 14 },
      { title: "Faixa de travessia com semáforo", code: 3 },
      { title: "Semáforo de pedestre com refúgio", code: 2 },
      { title: "Travessia em desnível separada", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "pedestrian_barriers",

    title: "Barreiras para pedestre",
    label: [
      { title: "Não presente", code: 1 },
      { title: "Presente", code: 2 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const instalacoesUVV2 = [
  {
    varName: "driver_side_sidewalk_or_footpath",
    title: "Calçada ou caminho informal - do lado do condutor",
    label: [
      { title: "Nenhum", code: 5 },
      { title: "Caminho informal 0m a <1m", code: 7 },
      { title: "Caminho informal ≥1m", code: 6 },
      { title: "Calçada 0m a <1m da rua", code: 4 },
      { title: "Calçada 1m a <3m da rua", code: 3 },
      { title: "Calçada ≥3m da rua", code: 2 },
      { title: "Calçada com barreira", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "passenger_side_sidewalk_or_footpath",
    title: "Calçada ou caminho informal - do lado do passageiro",
    label: [
      { title: "Nenhum", code: 5 },
      { title: "Caminho informal 0m a <1m", code: 7 },
      { title: "Caminho informal ≥1m", code: 6 },
      { title: "Calçada 0m a <1m da rua", code: 4 },
      { title: "Calçada 1m a <3m da rua", code: 3 },
      { title: "Calçada ≥3m da rua", code: 2 },
      { title: "Calçada com barreira", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "infrastructure_motorcycle",
    title: "Infraestrutura para veículos motorizados de duas rodas",
    label: [
      { title: "Nenhum", code: 6 },
      { title: "Pista para motocicletas dentro da pista principal", code: 5 },
      { title: "Pista para motocicletas - um sentido", code: 2 },
      { title: "Pista para motocicletas - dois sentidos", code: 4 },
      { title: "Pista para motocicletas - um sentido com barreira", code: 3 },
      {
        title: "Pista para motocicletas - dois sentidos com barreira",
        code: 1,
      },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "infrastructure_bicycle",
    title: "Infraestrutura para bicicletas",
    label: [
      { title: "Nenhum", code: 4 },
      { title: "Pista compartilhada com veículos", code: 6 },
      { title: "Pista extra larga ≥4.2m", code: 5 },
      { title: "Ciclovia exclusiva na pista principal", code: 3 },
      { title: "Calçada compartilhada", code: 7 },
      { title: "Ciclovia exclusiva", code: 2 },
      { title: "Ciclovia exclusiva com barreira", code: 1 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "school_zone_warning",
    title: "Advertência de zona escolar",
    label: [
      { title: "Nenhum aviso de zona escolar (escola presente)", code: 3 },
      {
        title:
          "Zona escolar - com sinalização vertical ou horizontal estáticas",
        code: 2,
      },
      { title: "Zona Escolar - luzes intermitentes", code: 1 },
      { title: "Não se aplica (nenhuma escola na localização)", code: 4 },
    ],
    selecionado: { title: "", code: null },
  },
  {
    varName: "Crossing_supervisor_in_school_zone",
    title: "Supervisor de travessia em zona escolar",
    label: [
      { title: "Não presente", code: 2 },
      {
        title:
          "Presente",
        code: 1,
      },
      { title: "Não se aplica (nenhuma escola na localização)", code: 3 },
    ],
    selecionado: { title: "", code: null },
  },
];

export const imageReference = [
  {
    varName: "image_reference",
    title: "Imagem de referência",
    label: [
      { title: "0 m", code: "0 m" },
      { title: "25 m", code: "25 m" },
      { title: "50 m", code: "50 m" },
      { title: "75 m", code: "75 m" },
      { title: "100 m", code: "100 m" },
    ],
    selecionado: { title: "", code: null },
  },
];
