import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { pesquisaApi } from '../../services/apiPesquisa.servise';
import imageCompression from 'browser-image-compression';
import { useDispatch, useSelector } from 'react-redux';
import { saveChange, setArrayLength, setIdSection, setImagens } from '../../store/reducers/dadosSegmentos';
import { authService } from '../../services/auth.service';
import { ModalComp } from '../Modal/Modal';
import { Loading } from '../Loading';
import { useNavigate } from 'react-router-dom';

const DragAndDrop = () => {
  const [incompleteRows, setImcompleteRows] = useState([]);
  const [openModalImagens, setOpenModalImagens] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [progressUpload, setProgressUplaod] = useState(0)
  const [acceptedUpload, setAcceptedUpload] = useState(false)
  const [filesUpload, setFilesUpload] = useState([])
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: { 'image/*': [] }, disabled: !!filesUpload.length && !!acceptedUpload });
  const [arrayImagens, setArrayImagens] = useState([])
  const slices = useSelector((state) => state);
  const state = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const dispatch = useDispatch()
  const navigate = useNavigate()



  const enviarImagens = async (segmentos, files, length) => {
    const tamanhoDoLote = 400;
    const numeroDeLotes = Math.ceil(length / tamanhoDoLote);
    const imgref = []
    const imgRefServidor = []
    let imageReferenceArrayClear =  ''
            let extension = ''
            let imageName = ''
    for (let i = 0; i < numeroDeLotes; i++) {
      const inicio = i * tamanhoDoLote;
      const fim = Math.min(inicio + tamanhoDoLote, length);
      let aux = 0
      const loteDesegmentos = []
      for (let j = inicio; j < fim; j++) {
        loteDesegmentos.push(segmentos[j])
      }
      const loteDeImagens = files.slice(inicio * 5, fim * 5);


      console.log("Enviando lote:", i, "Índices:", inicio, "a", fim - 1);
      for (let j = 0; j < loteDesegmentos.length; j++) {
        aux++
        const formData = new FormData();
        const user = authService.getLoggedUser();
        formData.append("image_reference", files[((5 * j))]);
        formData.append("latitude", state.dadosTabelaDeReferencia[j]["Latitude"]);
        formData.append("longitude", state.dadosTabelaDeReferencia[j]["Longitude"]);
        formData.append("centre_0", files[(5 * j)]);
        formData.append("centre_20", files[(5 * j) + 1]);
        formData.append("centre_40", files[(5 * j) + 2]);
        formData.append("centre_60", files[(5 * j) + 3]);
        formData.append("centre_80", files[(5 * j) + 4]);
        formData.append("header", state.idHeader);
        formData.append("coder_name", user.username);
        if (!imgref.includes(files[((5 * j))].name)) {
          try {
            imgref.push(files[((5 * j))].name)

            const resposta = await pesquisaApi.setSections(formData);
            if(resposta["image_reference"].includes('_')){
              imageReferenceArrayClear =  resposta["image_reference"].split('_');
              extension = imageReferenceArrayClear[1].split('.');
              imageName = `${imageReferenceArrayClear[0]}.${extension[1]}`
              
            }else{
              imageName = resposta["image_reference"]
    
            }
            if(imgRefServidor.includes(imageName)){
              await pesquisaApi.deleteSection(resposta["id_section"])
            }else{
              imgRefServidor.push(imageName)
              console.log("resposta", resposta)
            }
            saveSectionState(resposta, j);
            setProgressUplaod(Math.round((aux / length) * 100))
            setAcceptedUpload(true);
            console.log(j, progressUpload)
          } catch (error) {
            console.error('Erro ao enviar imagens:', error);
            try {
              await pesquisaApi.deleteHeader(state.idHeader);
            } catch (deleteError) {
              console.error('Erro ao deletar header:', deleteError);
            }
            setOpenModal(true)
            setOpenModalImagens(false)
            setOpenBackdrop(false)
            break;
          }
        }

      }

    }

  };

  const saveSectionState = async (resposta, i) => {
    dispatch(saveChange());
    // dispatch(setImagens({segmento: resposta, novoSegmento: true}));
    dispatch(
      setIdSection({
        index: i,
        item: {
          id_section: resposta.id_section,
          latitude: resposta.latitude,
          longitude: resposta.longitude,
        },
      })
    );
    console.log("data", resposta)
  }
  const searchImages = (files) => {
    let aux = 0
    let filesComplete = []
    let filesObserver = []
    let incompleteRow = []
    state.dadosTabelaDeReferencia.forEach((dados, i) => {

      ["centre - 0", "centre - 20", "centre - 40", "centre - 60", "centre - 80"].forEach(j => {
        Array.from(files).forEach((file) => {

          if (dados[j] === file.name) {
            if (filesObserver.find(img => img.name === file.name)) {
              console.log("tamanho", i, state.dadosTabelaDeReferencia.length + 1)
              if (!incompleteRow.find(j => j === i + 2)) {
                incompleteRow.push(i + 2)
              }
            }
            filesObserver.push(file)
            aux++
          }
        })
      })
      if (aux >= 5) {
        filesComplete = filesComplete.concat(filesObserver)
        filesObserver = []
        aux = 0
      } else {
        filesObserver = []
        incompleteRow.push(i + 2)
      }
    })
    console.log("filesComplete", filesComplete)
    setArrayImagens(filesComplete)
    dispatch(setArrayLength(Math.trunc(filesComplete.length / 5)))
    return [filesComplete, incompleteRow]
  }
  async function handleImageUpload(img) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(img, options);
      return (new File([compressedFile], img.name, { type: img.type }))
    } catch (error) {
      console.log(error);
    }

  }



  useEffect(() => {
    const workImgs = async () => {
      const [imagensCorrespondentes, incompleteRow] = searchImages(acceptedFiles)
      setImcompleteRows(incompleteRow)
      // const imagensComprimidas = await imagensCorrespondentes.forEach(img => handleImageUpload(img))
      // setImagens(imagensComprimidas)

      const files = acceptedFiles.map((file) => (
        <li key={file.path}>
          {file.path}
        </li>

      ));
      setFilesUpload(files)
    }
    if (acceptedFiles.length > 0) {
      setOpenBackdrop(true)
      workImgs()

    }
  }, [acceptedFiles]);

  useEffect(() => {
    const chamarEnviarImgs = async () => {
      console.log("arrayImagens", arrayImagens)
      await enviarImagens(state.segmentos, arrayImagens, state.length);
      setOpenBackdrop(false)
      setAcceptedUpload(true);
    }
    if (state.length > 0 && arrayImagens.length > 0) {
      chamarEnviarImgs()
    }

  }, [state.length, arrayImagens.length])

  useEffect(() => {
    if (incompleteRows.length > 0) {
      setOpenModalImagens(true)
    }
  }, [incompleteRows])


  return (
    <>
      {openBackdrop && <Loading hasProgress={true} progress={progressUpload} />}
      <Container sx={{ height: '80vh' }}>
        {openModal && <ModalComp open={openModal} title={"Erro inesperado!"}
          subtitle={"Ocorreu um erro inesperado, por favor tente novamente."}
        >
          <Button
            onClick={() => {
              setOpenModal(false)
              navigate("/")
            }}
          > Ok
          </Button>
        </ModalComp>}
        {incompleteRows.length != 0 ? (
          <div>
            <ModalComp
              open={openModalImagens}
              title={"Imagens ausentes ou duplicadas"}
              subtitle={"Estas linhas contêm imagens ausentes ou duplicadas:"}
            >
              {incompleteRows.map((item, i) => (
                <p key={i} style={{ color: "#FF0000" }}>
                  {item}
                </p>
              ))}

              <Button
                onClick={() => {
                  setOpenModalImagens(!openModalImagens);
                }}
              >
                Ok
              </Button>
            </ModalComp>
          </div>
        ) : (
          <></>
        )}
        <Box
          {...getRootProps()}
          sx={{
            backgroundColor: '#eeeeee',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30vh',
            border: !!filesUpload.length && !!acceptedUpload ? 'dashed 4px rgba(0, 255, 0, 1)' : 'dashed 4px rgba(169, 169, 169, 1)',
            cursor: 'pointer',
            mt: '14px'
          }}
        >
          <input {...getInputProps()} />

          {filesUpload.length === 0 &&
            <Box>
              <Typography variant="h6">Arraste e solte as imagens aqui ou clique para selecionar as imagens</Typography>
            </Box>
          }
          {filesUpload.length !== 0 && !!acceptedUpload && <Box>
            <Typography variant='h6' sx={{ color: "rgba(0, 255, 0, 1)" }}> As imagens foram salvas com sucesso. Pronto para iniciar codificação.
            </Typography>
          </Box>
          }
        </Box>
        {filesUpload.length !== 0 && (
          <>
            <Typography variant="h5">Imagens</Typography>
            <Box sx={{ height: '40vh', overflowY: 'scroll' }}>
              <ul>{filesUpload}</ul>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default DragAndDrop;
