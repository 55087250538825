import styles from "../../styles/Login.module.css";
import {
  Box,
  FormControl,
  TextField,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { AccountCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import imgBackground from "../../assets/mascaraBranca.png";
import imgBackground2 from "../../assets/background1.jpg";
import logos from "../../assets/CertareiRAPLogos.png";
import ButtonComp from "../../components/Button/Button";
import { authService } from "../../services/auth.service";
import { Loading } from "../../components/Loading";

const Login = () => {
  const navegate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [erroLogin, setErroLogin] = useState(0);
  const [token, setToken] = useState(localStorage.getItem("user"));
  const [hasLoading, setHasloading] = useState(false)

  const handlerOnKeyDown = (event) =>{
    if(event.key == 'Enter'){
      console.log("enter")
      handleClickLoginButton()
    }
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickLoginButton = async () => {
    setHasloading(true)
    try {
      let res = await authService.Login(username, password);
      console.log("login", res)
      setPassword("");
      setUsername("");
      let data = {
        token: res.data.token,
        expiry: res.data.expiry,
        id: res.data.id,
        username: username,
      };
      await authService.setLoggedUser(data);
      authService.getLoggedUser();
      navegate("/");
    } catch (error) {
      if(error){
        setErroLogin(error.response.status);
      }else{
        setErroLogin(500);
      }
    }finally{
      setHasloading(false)
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (token !== null) {
      navegate("/");
    }
  }, []);
  return (
    <>
    {
      hasLoading&&<Loading/>
    }
    <div className={styles.container} style={{backgroundImage: `url(${imgBackground2})`, 
     backgroundSize: "cover",
     backgroundPosition: "center",
     zIndex: 0, position: "absolute"}}></div>
    <div className={styles.container} style={{backgroundImage: `url(${imgBackground})`,

    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 2, position: "absolute"}}>
      <img src={logos} style={{height: 90, position: "fixed", top: 10, left: 10}}/>
      <Box className={styles.boxContainer}>
        <h2>Login</h2>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Nome de usuário"
          className={styles.textField}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="filled"
          sx={{ marginBottom: "12px" }}
        />
        <FormControl sx={{ m: 1, width: "64%" }} variant="filled">
          <FilledInput
          onKeyDown={handlerOnKeyDown}
            onChange={(e) => setPassword(e.target.value)}
            id="filled-adornment-password"
            placeholder="Senha"
            type={showPassword ? "text" : "password"}
            startAdornment={
              <InputAdornment position="start">
                <VpnKeyIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {erroLogin !== 0 && (
  erroLogin === 400 ? (
   
    <h5 className={styles.erroLogin}>Nome de usuario ou senha inválidos.</h5>
  ) : (
    
    <h5 className={styles.erroLogin}>Ocorreu um erro inesperado, por favor tente mais tarde.</h5>
  )
)}


        <ButtonComp onClick={handleClickLoginButton}>Entrar</ButtonComp>
      </Box>

    </div>
    </>
  );
};

export default Login;
