import * as React from 'react';
import {Button} from "@mui/material"
import "./Button.css"


 const ButtonComp = ({children, onClick, sx, disabled}) =>  {
 

  return (
      <Button onClick={onClick}  variant="contained" className={"button"} sx={sx} disabled={disabled}>{children}</Button>
  );
}

export default ButtonComp