import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dadosTabelaDeReferencia: [],
  idHeader: null,
  RoadName: "",
  snippet: "",
  distance: 0
};
const dadosHeader = createSlice({
  name: "dadosHeader",
  initialState,
  reducers: {
    setHeader: (state, { payload }) => {
      if (payload.dadosTabelaDeReferencia)
        state.dadosTabelaDeReferencia = payload.dadosTabelaDeReferencia;
      state.idHeader = payload.idHeader;
      state.RoadName = payload.RoadName;
      state.snippet = payload.snippet;
      state.distance = payload.distance;
    },

    reset: (state) => (state = initialState),
  },
});
export const { setHeader, reset } = dadosHeader.actions;
export default dadosHeader.reducer;
