import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { setCurrentSegment } from "../../store/reducers/dadosSegmentos";
import { useDispatch } from "react-redux";
export default function BasicSelect(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(props.selecionado.title);
  const [hasChange, setHasChange] = React.useState(false)
  const handleChange = (event) => {
    setValue(event.target.value);
    const newValue = event.target.value
    props.label.forEach((element) => {
      if (element.title === newValue) {
        const code = element.code;
        mandar(code, newValue);
      }
    });
  };

  // useEffect(() => {
  //   console.log("value", value);
  //   props.label.forEach((element) => {
  //     if (element.title === value && hasChange) {
  //       const code = element.code;
  //       mandar(code);
  //     } else if(element.title === value && !hasChange){
  //       setHasChange(true)
  //     }
  //   });
  // }, [value]);

  function mandar(code, value) {
    const obj = {
      title: props.title,
      selecionado: { title: value, code: code },
    };
    dispatch(setCurrentSegment(obj));
  }

  return (
    <Box sx={{ minWidth: 120, marginBottom: "16px" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={props.title}
          onChange={handleChange}
        >
          {props.label.map((label, i) => (
            <MenuItem key={i} value={label.title}>
              {label.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
