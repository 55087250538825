import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL_API;
console.log("base", baseUrl)
export const authService = {
  async Login(username, password) {
    return axios.post(`${baseUrl}api/login/`, {
      username: username,
      password: password,
    });
  },
  async setLoggedUser(data) {
    
    const usersArray = (await axios.get(`${baseUrl}users?search=${data.username}`)).data.results
    let isAdm = false
    for (let i in usersArray){
      if(usersArray[i].username == data.username){
        for(let j in usersArray[i].groups){
          if(usersArray[i].groups[j].name = 'AdmEncoder'){
            isAdm = true
          }
        }
      }
    }
    let dataComplete = data
    dataComplete['isAdm'] = isAdm
    let parsedData = JSON.stringify(data);
    console.log("isAdm", data, typeof(data))
    await localStorage.setItem("user", parsedData);
  },

  getLoggedUser() {
    let data = localStorage.getItem("user");
    if (!data) return null;
    try {
      let parsedData = JSON.parse(data);
      var nowDate = Date.now();
      var expiryTokenDate = Date.parse(parsedData.expiry);

      if (nowDate >= expiryTokenDate) {
        localStorage.removeItem("user");
        return null;
      } else {
        return parsedData;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  getLoggedUsername() {
    let data = localStorage.getItem("user");
    if (!data) return null;
    try {
      let parsedData = JSON.parse(data);
      var nowDate = Date.now();
      var expiryTokenDate = Date.parse(parsedData.expiry);

      if (nowDate >= expiryTokenDate) {
        localStorage.removeItem("user");
        return null;
      } else {
        return parsedData.username;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getLoggedUserId() {
    let parsedData = this.getLoggedUser();

    let res = await axios.get(`${baseUrl}users/?search=${parsedData.username}`);
    let id = await res.data.results[0].id;
    return id;
  },

  async Logout() {
    let parsedData = this.getLoggedUser();
    console.log(parsedData);
    let res = await axios.post(
      `${baseUrl}api/logout/`,
      {},
      {
        headers: {
          Authorization: `Token ${parsedData.token}`,
        },
      }
    );

    if (res) {
      console.log("entrou", res);
      window.localStorage.removeItem("user");
      return 1;
    } else {
      return null;
    }
  },
};