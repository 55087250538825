import axios from "axios";
import { authService } from "./auth.service";

const baseUrl = process.env.REACT_APP_BASE_URL_API;

export const pesquisaApi = {
  async setImg(img){
    let user = authService.getLoggedUser();
    if(user){
      try{
        const headers = await axios.post(`${baseUrl}pesquisa/v1/img`, img, {
          headers: {
            'Authorization': `Token ${user.token}`, 
            'Content-Type': 'multipart/form-data',
          }
        })
        console.log("headers", headers)
        return headers
      }catch (error){
        console.log(error)
      }
    }
  },
  async getHeaders() {
    let user = authService.getLoggedUser();
    if(user){

      let userLoggedId = await authService.getLoggedUserId();
      console.log("user", user)
      console.log("user.token", user.token)
      console.log("userLoggedIdddd", userLoggedId);
   

      if(!user.id){
        await authService.Logout();
      }
      console.log("userLoggedId", user.token);
      return axios.get(`${baseUrl}pesquisa/v1/headers/${user.id}/user-headers/`, {
        headers: {
          Authorization: `Token ${user.token}`
          
        }
      });
    }else{
      return null
    }
  },
  async getHeadersAnotherUser(anotherUserId) {
    let user = authService.getLoggedUser();
    if(user){

      let userLoggedId = await authService.getLoggedUserId();
      console.log("user", user)
      console.log("user.token", user.token)
      console.log("userLoggedId", userLoggedId);
   
     
      
      console.log("userLoggedId", user.token);
      return axios.get(`${baseUrl}pesquisa/v1/headers/${anotherUserId}/user-headers/`, {
        headers: {
          Authorization: `Token ${user.token}`
          
        }
      });
    }else{
      return null
    }
  },
  async getUsers() {
    let user = authService.getLoggedUser();
    if(user){

      let userLoggedId = await authService.getLoggedUserId();
      console.log("user", user)
      console.log("user.token", user.token)
      console.log("userLoggedId", userLoggedId);
   
     
      
      console.log("userLoggedId", user.token);
      const res = await axios.get(`${baseUrl}users`);
      console.log("RES", res)
      return res 
    }else{
      return null
    }
  },
  async getSections(idHeader) {
let user = authService.getLoggedUser();

    if(user){

      return axios.get(`${baseUrl}pesquisa/v1/sections?search=${idHeader}`, {
        headers: {
          Authorization: `Token ${user.token}`, 
        },
      });
    }else{
      return null
    }
  },

  async setSections(formData) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios
        .post(`${baseUrl}pesquisa/v1/sections`, formData, {
           headers: {
      'Content-Type': 'multipart/form-data',
     'Authorization': `Token ${user.token}`,  // Envia o token de autenticação no cabeçalho
  
    }
        })
  
      return res.data;
    }else{
      return null
    }
  },
  async uploadSections(formData, id) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios
        .patch(`${baseUrl}pesquisa/v1/sections/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${user.token}`, 
          },
        })
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    }else{
      return null
    }
  },

  async setHeaders(data) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios.post(`${baseUrl}pesquisa/v1/headers`, data, {
        headers: {
          'Authorization': `Token ${user.token}`, 
        },
      });
      let datas = await res.data;
      return datas;
    }else{
      return null
    }
  },
  async uploadHeader(data, id) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios.patch(`${baseUrl}pesquisa/v1/headers/${id}`, data, {
        headers: {
          'Authorization': `Token ${user.token}`, 
        },
      });
      let datas = await res.data;
      console.log("response", datas)
      return datas;
    }else{
      return null
    }
  },
  async deleteHeader(id) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios.delete(`${baseUrl}pesquisa/v1/headers/${id}`, {
        headers: {
          'Authorization': `Token ${user.token}`, 
        },
      });
      let datas = await res.data;
      console.log("response", datas)
      return datas;
    }else{
      return null
    }
  },
  async deleteSection(id) {
let user = authService.getLoggedUser();

    if(user){

      let res = await axios.delete(`${baseUrl}pesquisa/v1/sections/${id}`, {
        headers: {
          'Authorization': `Token ${user.token}`, 
        },
      });
      let datas = await res.data;
      console.log("response", datas)
      return datas;
    }else{
      return null
    }
  },
};
