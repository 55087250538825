import { Box, Fade, IconButton, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ButtonComp from "../Button/Button";

export default function ImageModal({ images, image, open, handleClose }) {
  const [indexImg, setIndexImg] = useState(images.indexOf(image));
  const [img, setImg] = useState(image);
  const imgRef = useRef();

  useEffect(() => {
    setIndexImg(images.indexOf(image));
    console.log("images", images);
  }, [image, images]);

  useEffect(() => {
    setImg(images[indexImg]);
  }, [indexImg, images]);

  const handlePrev = () => {
    setIndexImg((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setIndexImg((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open} timeout={500} sx={{ outline: "none" }}>
          <div
            style={{
              outline: "none",
              height: "60%",
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton size="large" onClick={handlePrev} disabled={indexImg <= 0}>
              <ArrowBackIosNewIcon sx={{color:"#fff"}}/>
            </IconButton>
            <TransformWrapper centerOnInit={true} disablePadding={true}>
              <TransformComponent>
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    ref={imgRef}
                    src={img}
                    alt="asd"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </Box>
              </TransformComponent>
            </TransformWrapper>
            <IconButton size="large" color="#fff" onClick={handleNext} disabled={indexImg >= 4}>
              <ArrowForwardIosIcon sx={{color:"#fff"}}/>
            </IconButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
