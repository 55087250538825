import styles from "../../styles/Forms2.module.css";
import React from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as XLSX from "xlsx";
import { exportExcel } from "../../utils/excel";
import ButtonComp from "../../components/Button/Button";
import { authService } from "../../services/auth.service";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageView } from "../../contexts/imageView";
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from "../../store/reducers/dadosHeader";
import { Container } from "@mui/material";

export default function Forms() {
  const location = useLocation();
  const [name, setName] = useState(location.state.coderName);
  const [roadName, setRoadName] = useState(location.state.roadName);
  const [initialKm, setInitialKm] = useState(0);
  const [snippet, setSnippet] = useState(location.state.snippet);
  const [arquivoRef, setArquivoRef] = useState(location.state.arquivo);
  const [dataImport, setDataImport] = useState();
  const images = location.state.images;
  const [openModal, setOpenModal] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [hasCod, setHasCod] = useState(false);
  const [itensFaltando, setItensFaltando] = useState([]);
  const { imageView, dispatchImageView } = useContext(ImageView);
  const [idUser, setIdUser] = useState();
  const dispacth = useDispatch();
  const navigate = useNavigate()
  const slices = useSelector((state) => state);
  const stateGlobal = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const handleClose = () => {
    setOpenModal(false);
    setOpenModalSave(false);
  };

  const onHendlerSave = () =>{
    headersAxiosData();
  }
  const headersAxiosData = async () => {
    console.log("dataImport", dataImport)
    const data = {
      coding_date: new Date().toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      }),
      road_survey_date: new Date().toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      }),
      road_name: roadName,
      data_reference_table: JSON.stringify(dataImport),
      section_name: snippet,
      distance: initialKm,
      length: 0.9,
      coder: idUser,
      hasImgs: false
    };
    try{
      const res = await pesquisaApi.setHeaders(data);
      const idHead = res.id_header;
      console.log("idHead", idHead)
  
      if (idHead && dataImport) {
        const dataPayload = {
          dadosTabelaDeReferencia: dataImport,
          idHeader: idHead,
          RoadName: roadName,
          snippet: snippet,
          distance: initialKm,
        };
        dispacth(setHeader(dataPayload));
      }
      navigate("/uploadImages")
    }catch(error){

    }
  };


  function handleFile(e) {
    var file = e.target.files[0];
    setArquivoRef(file);
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const bufferArray = e.target.result;
      console.log("bufferArray",bufferArray)
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let importData = [];
      let aux = 0;
      Object.keys(data).forEach((i) => {
        let dados = {};
        Object.keys(data[i]).forEach((j) => {
          dados[j] = data[i][j];
          // ( data[i][j], j)
        });

        importData[aux] = dados;
        aux++;
      });
      
      importData.forEach(item =>{
        if(typeof(item["Latitude"]) === 'string'){
          item["Latitude"] = parseFloat(item["Latitude"].replace(',', '.'));
        } 
        if(typeof(item["Longitude"]) === 'string'){
          item["Longitude"] = parseFloat(item["Longitude"].replace(',', '.'));
        }
      })
      setDataImport(importData);
    };
  }
  useEffect(() => {
    const axiosData = async () => {
      const data = await authService.getLoggedUserId();
      console.log("data", data);
      setIdUser(data);
    };

    axiosData();
  }, []);




  useEffect(() => {
    console.log(imageView.hasFlex);
  }, [imageView]);
  return (
    <>
      <LateralMenu></LateralMenu>
      <Container style={{display: "flex", justifyContent: "space-between", }}>
         
          <Box className={styles.divNoRef}>
            <TextField
              id="name"
              label="Nome da via"
              variant="standard"
              fullWidth
              onChange={(e) => {
                setRoadName(e.target.value);
              }}
            />
            <TextField
              sx={{ marginBottom: "12px" }}
              id="name"
              label="Trecho"
              variant="standard"
              fullWidth
              onChange={(e) => {
                setSnippet(e.target.value);
              }}
            />
            <TextField
              sx={{ marginBottom: "12px" }}
              id="InitialKm"
              label="Km inicial"
              variant="standard"
              type="number"
              fullWidth
              onChange={(e) => {
                setInitialKm(e.target.value);
              }}
            />
            {
                arquivoRef? ("Upload do arquivo feito com sucesso"): ("Faça upload do aquivo de referência.")
            }
            <Box sx={{display: "flex" , justifyContent: "space-between", alignItems: "center", marginTop: "12px",}}>
            <Button
              sx={{
           
                backgroundColor: "#367f59",
                ":hover": { backgroundColor: "#2b6547" },
              }}
              variant="contained"
              component="label"
              onChange={(e) => handleFile(e)}
            >
              Upload
              <input hidden key={arquivoRef ? arquivoRef.name : ''} type="file" />
            </Button>
            {
                arquivoRef? (
            <IconButton sx={{marginLeft: 2}} onClick={()=>{
                setArquivoRef(null)
                setDataImport(null)
            }}>
                <DeleteIcon fontSize="large"></DeleteIcon>
            </IconButton>
                ): (
                    <></>
                    )
            }
            </Box>
            <Box sx={{width: "100%", display: "flex", justifyContent: "right"}}>
          <ButtonComp 
          disabled={!arquivoRef || !dataImport || !roadName || !snippet || !initialKm} 
          onClick={onHendlerSave}
          >Salvar</ButtonComp>
            </Box>
          </Box>
        
      </Container>
    </>
  );
}
