import React from "react";
import { ListItemText, ListItemButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export function UserItem({
  encoderName, id
  
}) {
 
  const navegate = useNavigate();
  const dispacth = useDispatch();
 
  const onClickHandler = async () => {
      navegate('/', {state: {anotherUserId: id}})

  };

  return (
    <>
    

    <Box sx={{display: "flex", borderBottom: "1px solid #808080", height: "14vh"}}>

      <ListItemButton
        alignItems="flex-start"
        onClick={onClickHandler}
      >
        <ListItemText
          primary={encoderName}
          // secondary={
          //   <>
          //     <Typography>
          //       nome da via: {codificationTitle}, trecho: {lastSectionName}
          //     </Typography>
          //     <Typography>
          //       Data da ultima alteração: {lastDateAlteretion}
          //     </Typography>
          //   </>
          // }
        />
        
      </ListItemButton>
    </Box>
    
    
    </>
  );
}
