import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Forms from "./pages/forms2/Forms2";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/login/Login";
import { MuiThemeProvider } from "./styles/MuiTheme";
import Home from "./pages/home/Home";
import { authService } from "./services/auth.service";
import { ImageViewProvider } from "./contexts/imageView";
import { Provider } from "react-redux";
import { store } from "./store";
import UploadImages from "./pages/uploadImages/uploadImages";
import Search from "./pages/forms/Forms";
import { PersistGate } from 'redux-persist/integration/react'
  import {persistStore} from "redux-persist";
import Codificadores from "./pages/codificadores/codificadores";
import Manual from "./pages/manual/Manual";
import EditSegments from "./pages/editSegments/editSegments";
const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store)
const PrivateRoutes = () => {
  let auth = authService.getLoggedUser();
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const LoginRoute = () => {
  let auth = authService.getLoggedUser();
  return auth ? <Navigate to="/" /> : <Outlet />;
};

const AdmPrivateRoutes = () =>{
  let auth = authService.getLoggedUser();
  let {isAdm} = auth 
  return isAdm && auth? <Outlet /> : <Navigate to='/login'/>;
}
root.render(
  <React.StrictMode>
    <MuiThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ImageViewProvider>
            <Routes>
              <Route element={<LoginRoute />}>
                <Route path="/login" element={<Login />} />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route path="/forms" element={<Forms />} />
                <Route path="/" element={<Home />} />
                <Route path="/uploadImages" element={<UploadImages/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/manual" element={<Manual/>}/>
              </Route>
              <Route element={<AdmPrivateRoutes />}>
                <Route path="codificadores" element={<Codificadores/>} />
                <Route path="/forms" element={<Forms />} />
                <Route path="/" element={<Home />} />
                <Route path="/uploadImages" element={<UploadImages/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/manual" element={<Manual/>}/>
                <Route path="/edit" element={<EditSegments/>}/>
              </Route>
            </Routes>
          </ImageViewProvider>
        </BrowserRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
