import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export function ModalComp({ title, subtitle, children, open }) {
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: 400, overflowY: "scroll", height: "400px" }}
        >
          <h2 id="parent-modal-title">{title}</h2>
          <p id="parent-modal-description">{subtitle}</p>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
