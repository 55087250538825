import {
  fluxoObservado,
  velocidade,
  caracteristicasDaVia,
  margemDaVia,
  caracteristicasDaVia2,
  intersecoes,
  instalacoesUVV,
  instalacoesUVV2,
  imageReference,
  informacoesExtras,
} from "../../environments/labels";
export const formState = [
  ...informacoesExtras,
  ...fluxoObservado,
  ...velocidade,
  ...caracteristicasDaVia,
  ...margemDaVia,
  ...caracteristicasDaVia2,
  ...intersecoes,
  ...instalacoesUVV,
  ...instalacoesUVV2,
  ...imageReference,
];
