import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import {
  ExitToApp as ExitToAppIcon,
  Article as ArticleIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  Mail as MailIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import logos from "../../assets/CertareiRAPLogosBrancas.png";
import { authService } from "../../services/auth.service";
import { ModalComp } from "../Modal/Modal";

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft({ isHome, isForm }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const user = authService.getLoggedUser();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const slices = useSelector((state) => state);
  const state = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const [trechosIncompletos, setTrechosIncompletos] = useState([]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const checkTrechos = () => {
    const { segmentos } = state;
    let newTrechosIncompletos = [];
    Object.keys(segmentos).forEach((i) => {
      let itensFaltantes = [];
      Object.keys(segmentos[i]).forEach((j) => {
        if (segmentos[i][j].selecionado.code === null) {
          itensFaltantes.push(segmentos[i][j].title);
        }
      });
      if (itensFaltantes.length) {
        newTrechosIncompletos.push({ trecho: parseInt(i) + 1, faltantes: itensFaltantes });
      }
    });
    setTrechosIncompletos(newTrechosIncompletos);
    setOpenModal(true);
  };

  useEffect(() => {
    if (trechosIncompletos.length > 0) {
      setOpenModal(true);
    }
  }, [trechosIncompletos]);

  const handleLogout = async () => {
    const res = await authService.Logout();
    if (res) {
      navigate("/login");
    }
  };

  const menuItems = [
    { text: "Home", icon: <HomeIcon />, action: () => navigate("/") },
    { text: "Planilha de referência", icon: <MailIcon />, action: () => console.log("download") },
    { text: "Codificadores", icon: <PersonIcon />, action: () => navigate("/codificadores"), disabled: !user.isAdm },
    { text: "Manual do codificador", icon: <ArticleIcon />, action: () => navigate("/manual") },
    { text: "Sair", icon: <ExitToAppIcon />, action: handleLogout },
  ];

  return (
    <>
    {

trechosIncompletos.length&&<ModalComp open={openModal}>
        <Box>
          {trechosIncompletos.map((item, index) => (
            <div key={index}>
              <Typography variant="h6">Trecho: {item.trecho}</Typography>
              <Typography>Itens:</Typography>
              {item.faltantes.map((faltante, idx) => (
                <Typography key={idx} color={"red"}>{faltante}</Typography>
              ))}
            </div>
          ))}
          <Button onClick={() => setOpenModal(false)}>Ok</Button>
        </Box>
      </ModalComp >
    }{
      !trechosIncompletos.length&& <ModalComp
      open={openModal}
      title={"Todos os trechos estão completos."}
      >
         <Button onClick={() => setOpenModal(false)}>Ok</Button>
      </ModalComp>
    }
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: "none" }) }}>
                <MenuIcon />
              </IconButton>
              <img src={logos} style={{ height: 50, margin: "15px 0px 10px 10px" }} />
            </div>
            {isHome && (
              <Button
                sx={{ background: "#fff", ":hover": { background: "#fff" } }}
                onClick={() =>
                  navigate("/Forms", {
                    state: {
                      coderName: "",
                      roadName: "",
                      snippet: "",
                      arquivo: null,
                      images: [],
                      hasNew: true,
                    },
                  })
                }
              >
                Nova Codificação
              </Button>
            )}
            {isForm && (
              <Button
                sx={{ background: "#fff", ":hover": { background: "#fff" } }}
                onClick={checkTrechos}
              >
                Checar trechos
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={item.action} disabled={item.disabled}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </>
  );
}
