import styles from "../../styles/Forms.module.css";
import React from "react";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import Tabs from "../../components/Tabs/Tabs";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { exportExcel } from "../../utils/excel";
import ButtonComp from "../../components/Button/Button";
import { authService } from "../../services/auth.service";

import { ImageView } from "../../contexts/imageView";
import { ModalComp } from "../../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { pesquisaApi } from "../../services/apiPesquisa.servise";
import cloneDeep from "lodash.clonedeep";
import { saveChange, setAllSegments, setImagens } from "../../store/reducers/dadosSegmentos";
import { useLocation, useNavigate } from "react-router-dom";
import { formState } from "../../store/reducers/data";
import { List, ListItemText, ListSubheader, Paper } from "@mui/material";
import { SectionItem } from "../../components/SectionItem/SectionItem";
import { Loading } from "../../components/Loading";
export default function EditSegments() {
    const slices = useSelector((state) => state);
    const [imagens, setImagens] = useState([])
    const[openModal, setOpenModal] = useState(false)
    const[openBackdrop,setOpenBackdrop] = useState(false)
  const state = { ...slices.dadosSegmentos, ...slices.dadosHeader };
  const navigate = useNavigate();
  const OnCLickDeleteReapeatHadler = async() =>{
    setOpenBackdrop(true)
    for (const id of state.duplicateImagesSectionId) {
      await pesquisaApi.deleteSection(id);
    }
    setOpenBackdrop(false)
    navigate(-1);
 
  }
  useEffect(()=>{
    const imgsNoRepeat = []
    const imgs = []
    Object.keys(state.segmentos).forEach(i => {
        Object.keys(state.segmentos[i]).forEach(j =>{
            if(state.segmentos[i][j].varName === "image_reference"){
              let imageName = ''
              const imageReferenceArray = state.segmentos[i][j].selecionado.code.split("/");
              if(imageReferenceArray[imageReferenceArray.length - 1].includes('_')){
                const imageReferenceArrayClear =  imageReferenceArray[imageReferenceArray.length - 1].split('_');
                const extension = imageReferenceArrayClear[1].split('.');
                imageName = `${imageReferenceArrayClear[0]}.${extension[1]}`
              }else{
                imageName = imageReferenceArray[imageReferenceArray.length - 1]
                console.log("entrou no else")
              }
              if(imgsNoRepeat.includes(imageName)){
                imgs.push({img: imageName, repeat: true})
              }else{
                imgs.push({img: imageName, repeat: false})
                imgsNoRepeat.push(imageName)
              }
            }
        })
        setImagens(imgs)
    })
  },[])
  return (
    <>
    {openBackdrop && <Loading/>}
    {
      <ModalComp
      open={openModal}
      title={"Ação irreversível"}
      subtitle={"A ação é irreversível. Ao prosseguir, todos os trechos em vermelho serão excluídos PERMANENTEMENTE. Deseja continuar?"}
      >
        <Box>
          <Button
          onClick={OnCLickDeleteReapeatHadler}
          >
            Sim
          </Button>
          <Button onClick={()=> setOpenModal(false)}>
            Não
          </Button>
        </Box>
      </ModalComp>
    }
      <LateralMenu></LateralMenu>
      <main>
      <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#e7eaef",
          }}
        >
          

          <Paper elevation={3}>
            <List
              sx={{
                width: "100%",
                minWidth: 1000,
                overflow: "auto",
                maxHeight: "65vh",
                padding: 0,
              }}
            >
              <ListSubheader sx={{ marginBottom: "-30px", marginTop: "" }}>
                <h2>Trechos do Segmento {state.RoadName} - {state.snippet}</h2>
              </ListSubheader>
              {
                imagens.map((item, i) => <SectionItem imagRef={item.img} numTrecho={i+1} trecho={i+1} repeat={item.repeat}  key={i} />)
              }
            </List>
          </Paper>
          <Box mt={2}>
          <ButtonComp onClick={()=> setOpenModal(true)}>Excluir duplicados</ButtonComp>
          </Box>
        </Box>
        
      </main>
    </>
  );
}
