import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSegment } from "../../store/reducers/dadosSegmentos";

export default function RowRadioButtonsGroup({
  title,
  label,
  selecionado,
  disabled,
}) {
  const [value, setValue] = React.useState(selecionado.title);
  const hasDisabled = title === "Qualidade da curva" || title === "Diferencial de velocidades";
  const slices = useSelector((state) => state);
  const segmentos = { ...slices.dadosSegmentos.segmentos };
  const dispatch = useDispatch();
  const [hasChange, setHasChange] = React.useState(false)
  const handleChange = (event) => {
    setValue(event.target.value);
    const newValue = event.target.value
    label.forEach((element) => {
      if (element.title === newValue) {
        const code = element.code;
        mandar(code, newValue);
      }
    });
    
  };

  // useEffect(() => {
  //   label.forEach((element) => {
  //     if (element.title === value) {
  //       const code = element.code;
  //       mandar(code);
  //     }else if(element.title === value && !hasChange){
  //       setHasChange(true)
  //     }
  //   });
  // }, [value]);

  function mandar(code, value) {
    const obj = {
      title: title,
      selecionado: { title: value, code: code },
    };
    dispatch(setCurrentSegment(obj));
  }
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {label.map((label) => (
          (
            label.title === "Não se aplica"? null : (
              <FormControlLabel
              key={label.title}
              disabled={
                disabled
                  ? disabled
                  : hasDisabled && label.title === "Não se aplica"
              }
              value={label.title}
              control={<Radio />}
              label={label.title}
              hidden={label.title === "Não se aplica"}
            />
            )
          )
         
        ))}
      </RadioGroup>
    </FormControl>
  );
}
