import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import styles from "./Tabs.module.css";
import Box from "@mui/material/Box";
import RadioButton from "../RadioButton/RadioButton";
import SelectComponent from "../Select/Select";
import {
  fluxoObservado,
  velocidade,
  caracteristicasDaVia,
  margemDaVia,
  caracteristicasDaVia2,
  intersecoes,
  instalacoesUVV,
  instalacoesUVV2,
} from "../../environments/labels";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ImageView } from "../../contexts/imageView";
import { useSelector } from "react-redux";

//  instalacoesUVV
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [segmentos, paginaAtual, validators, hasChanged] = useSelector((state) => [
    state.dadosSegmentos.segmentos,
    state.dadosSegmentos.paginaAtual,
    state.dadosSegmentos.validators,
    state.dadosSegmentos.hasChanged,
  ]);
  const [hasLoading, setHasLoading] = useState(false);
  const { imageView, dispatchImageView } = useContext(ImageView);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let loadNewValue = async () => {
      setValue(1);
      setTimeout(()=>{
        setValue(0);

      }, "1")

    };

    loadNewValue();
  }, [paginaAtual,hasChanged]);

  return (
    <div
      style={{ marginLeft: "12px", marginRight: "12px" }}
      className={imageView.hasFlex ? styles.tabsFlewView : ""}
    >
      <Box
        sx={{
          borderRadius: "8px",
          border: "solid 1px grey",
          marginRight: "10px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {hasLoading ? (
            <Box>loucura loucura</Box>
          ) : (
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Fluxo observado" {...a11yProps(0)} />
              <Tab label="Velocidade" {...a11yProps(1)} />
              <Tab label="Características da via" {...a11yProps(2)} />
              <Tab label="Características da via 2" {...a11yProps(3)} />
              <Tab label="Atributos da margem da via" {...a11yProps(4)} />
              <Tab label="Interseções" {...a11yProps(5)} />
              <Tab label="Instalações UVV" {...a11yProps(6)} />
              <Tab label="Instalações UVV2" {...a11yProps(7)} />
            </Tabs>
          )}
        </Box>

        <TabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {fluxoObservado.map((obj, i) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={i}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else if (objeto.title != "id_section") {
                return (
                  
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {velocidade.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                    disabled={obj.title === "Diferencial de velocidades"? true : false}
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {caracteristicasDaVia.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                    disabled={
                      objeto.title == "Qualidade da curva"
                        ? validators[paginaAtual - 1].curveQuality
                        : false
                    }
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {caracteristicasDaVia2.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {margemDaVia.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {intersecoes.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                    disabled={
                      objeto.title === "Qualidade da intersecção" ||
                      objeto.title ==="Canalização da intersecção" || objeto.title === "Volume de tráfego na via transversal"
                        ? validators[paginaAtual - 1].intersecion
                        : false
                    }
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

        <TabPanel value={value} index={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {instalacoesUVV.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                    disabled={
                    objeto.title === "Qualidade da travessia de pedestres" 
                      ? validators[paginaAtual - 1].pedestrianQuality
                      : false
                    }

                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {instalacoesUVV2.map((obj) => {
              let objeto = {};
              Object.keys(segmentos[paginaAtual - 1]).forEach((i) => {
                if (segmentos[paginaAtual - 1][i].title === obj.title) {
                  objeto = segmentos[paginaAtual - 1][i];
                }
              });
              if (obj.label.length >= 9) {
                return (
                  <SelectComponent
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    obj={objeto}
                    title={objeto.title}
                    label={obj.label}
                  ></SelectComponent>
                );
              } else {
                return (
                  <RadioButton
                    key={objeto.title}
                    selecionado={objeto.selecionado}
                    title={objeto.title}
                    label={objeto.label}
                   
                  ></RadioButton>
                );
              }
            })}
          </Box>
        </TabPanel>

      </Box>
 
    </div>
  );
}
