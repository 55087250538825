import { createSlice } from "@reduxjs/toolkit";
import { formState } from "./data";
import clonedeep from "lodash.clonedeep";

const arrayNoIncludes = ["Image reference", "centre_0", "centre_20", "centre_40", "centre_60", "centre_80", "Latitude", "Longitude","id_section" ]
const speedNumberTransform = (text =>{
  return text.includes('km')? Number(text.split('km')[0]) : Number(text.split('mph')[0]) * 1.60934
}) 
const handlerEqualsValidators = (state, payload) =>{
  let segmentos = { ...state.segmentos };
  let validators = { ...state.validators };
  const valuePayload = speedNumberTransform(payload.selecionado.title)


  const itemSearch = payload.title === "Limite de velocidade para motocicletas"? "Limite de velocidade para caminhões" : "Limite de velocidade para motocicletas"

        Object.keys(segmentos[state.paginaAtual - 1]).forEach((j) => {
          if (segmentos[state.paginaAtual - 1][j].title === itemSearch) {
            const valueSearch = speedNumberTransform(segmentos[state.paginaAtual - 1][j].selecionado.title)
            if(Math.abs(valuePayload - valueSearch) <= 20){
              Object.keys(segmentos[state.paginaAtual - 1]).forEach((k) => {
                if(segmentos[state.paginaAtual - 1][k].title === "Diferencial de velocidades"){
                  validators[state.paginaAtual - 1]["speedDifferential"] = false
                  segmentos[state.paginaAtual - 1][k].selecionado.code = 1
                  segmentos[state.paginaAtual - 1][k].selecionado.title = "Não presente"
                }
            });
            }else{
              Object.keys(segmentos[state.paginaAtual - 1]).forEach((k) => {
                if(segmentos[state.paginaAtual - 1][k].title === "Diferencial de velocidades"){
                  validators[state.paginaAtual - 1]["speedDifferential"] = true
                  segmentos[state.paginaAtual - 1][k].selecionado.code = 2
                  segmentos[state.paginaAtual - 1][k].selecionado.title = "Presente"
                  
                }
            });
            }
          }
      });
      return [validators, segmentos];
 
}
const handlerVaridators = (state, item, codeSearch, arraySearch, validator, page) => {
  let validators = { ...state.validators };
  let segmentos = { ...state.segmentos };
  if (item.selecionado.code === codeSearch) {
    validators[page][validator] = true;
    Object.keys(segmentos[page]).forEach((i) => {
      arraySearch.forEach((fetchedItem) => {
        if (segmentos[page][i].title === fetchedItem.title) {
          segmentos[page][i].selecionado.code =
            fetchedItem.code;
          segmentos[page][i].selecionado.title =
            fetchedItem.text;
        }
      });
    });
  } else {
    validators[page][validator] = false;
    if(validator === 'curveQuality'){
      Object.keys(segmentos[page]).forEach((i) => {
        arraySearch.forEach((fetchedItem) => {
          if (segmentos[page][i].title === fetchedItem.title &&  segmentos[page][i].selecionado.title === 'Não se aplica'
            &&  segmentos[page][i].selecionado.code === 3
          ) {
            segmentos[page][i].selecionado.code =
              null
            segmentos[page][i].selecionado.title =
             ''
             console.log("qualidade check")
          }
        });
      });
    }
  }

  return [validators, segmentos];
};

const initialState = {
  segmentos: { 0: { ...clonedeep(formState) } },
  paginaAtual: 1,
  length: 0,
  isSave: false,
  hasChanged: false,
  imagens: [],
  validators: {
    0: {
      curveQuality: false,
      intersecion: true,
      speedDifferential: true,
      pedestrianQuality: true
    },
  },
  duplicateImagesSectionId: []
};
export const dadosSegmentosSlice = createSlice({
  name: "dadosSegmentos",
  initialState,
  reducers: {
    setCurrentSegment: (state, { payload }) => {
      Object.keys(state.segmentos[state.paginaAtual - 1]).forEach((i) => {
        if (state.segmentos[state.paginaAtual - 1][i].title === payload.title) {
          state.segmentos[state.paginaAtual - 1][i].selecionado =
            payload.selecionado;
          if (payload.title === "Curvatura") {
            handlerVaridators(
              state,
              payload,
              1,
              [{ title: "Qualidade da curva", code: 3, text: "Não se aplica" }],
              "curveQuality", state.paginaAtual - 1
            );
          } else if (payload.title === "Tipo de interseção") {
            handlerVaridators(
              state,
              payload,
              12,
              [
                {
                  title: "Qualidade da intersecção",
                  code: 3,
                  text: "Não se aplica",
                },
                {
                  title: "Canalização da intersecção",
                  code: 1,
                  text: "Não presente",
                },
                {
                  title: "Volume de tráfego na via transversal",
                  code: 7,
                  text: "Não se aplica", 
                }
              ],
              "intersecion", state.paginaAtual - 1
            );
          }else if(payload.title === "Limite de velocidade para motocicletas" ||payload.title ===  "Limite de velocidade para caminhões"){
            const [newValidators, newSegmentos] = handlerEqualsValidators(state, payload)
            state.validators = newValidators
            state.segmentos = newSegmentos
          } else if(payload.title === "Infraestrutura para travessia de pedestres - na rodovia de inspeção"){
            handlerVaridators(
              state,
              payload,
              7,
              [
                {
                  title: "Qualidade da travessia de pedestres",
                  code: 3,
                  text: "Não se aplica",
                }
              ],
              "pedestrianQuality",state.paginaAtual - 1
            );
          }
        }
      });
    },

    saveChange: (state, { payload }) => {
      if (state.isSave === false) {
        state.isSave = true;
      }
    },
    setCurrentPage: (state, {payload}) => {
      state.paginaAtual = payload
    },
    setArrayLength: (state, { payload }) => {
      let segmentos = { ...state.segmentos };
      let validators = { ...state.validators };
      console.log("payload", payload);
      for (let i = Object.keys(state.segmentos).length; i <= payload - 1; i++) {
        segmentos[i] = { ...clonedeep(formState) };
        validators[i] = {
          curveQuality: false,
          intersecion: true,
          speedDifferential: true,
          pedestrianQuality: true
        };
      }

      return {
        ...state,
        length: payload,
        segmentos: segmentos,
        validators: validators,
      };
    },

    setImageRef: (state, { payload }) => {
      for (let i = 0; i < state.length; i++) {
        Object.keys(state.segmentos[i]).forEach((item) => {
          if (state.segmentos[i][item].title === "Imagem de referência") {
            state.segmentos[i][item].label = payload.labels[0][i];
          }
        });
      }
    },

    setSegmento: (state, { payload }) => {
      return { ...state, paginaAtual: payload };
    },

    setAllSegments: (state, { payload }) => {
      state.segmentos = { ...payload.segmentos };
      state.length = payload.length;
      for (let i = 0; i < payload.length; i++) {
        state.validators[i] = {
          curveQuality: false,
          intersecion: true,
          speedDifferential: true,
          pedestrianQuality: true
        };}
      for (let i = 0; i < payload.length; i++) {
        state.validators[i] = {
          curveQuality: false,
          intersecion: true,
          speedDifferential: true,
          pedestrianQuality: true
        };
        // Object.keys(state.segmentos[i]).forEach(j =>{
        //   if (state.segmentos[i][j].title === "Curvatura" && state.segmentos[i][j].selecionado.code === 1) {
        //     state.validators[i].curveQuality = true
        //     if ( state.segmentos[i][j].title === "Qualidade da curva") {
        //        state.segmentos[i][j].selecionado.code =3
        //        state.segmentos[i][j].selecionado.title = "Não se aplica"
        //     }
           
        //   }
        // })
        Object.keys(state.segmentos[i]).forEach((j) => {
            if(state.segmentos[i][j].title === "Etiqueta de pista" && state.segmentos[i][j].selecionado.code){
              state.paginaAtual = i +1;
            }
            if (state.segmentos[i][j].title === "Curvatura") {
              handlerVaridators(
                state,
                state.segmentos[i][j],
                1,
                [{ title: "Qualidade da curva", code: 3, text: "Não se aplica" }],
                "curveQuality",i
              );
            } else if (state.segmentos[i][j].title === "Tipo de interseção") {
              handlerVaridators(
                state,
                state.segmentos[i][j],
                12,
                [
                  {
                    title: "Qualidade da intersecção",
                    code: 3,
                    text: "Não se aplica",
                  },
                  {
                    title: "Canalização da intersecção",
                    code: 1,
                    text: "Não presente",
                  },
                  {
                    title: "Volume de tráfego na via transversal",
                    code: 7,
                    text: "Não se aplica", 
                  }
                ],
                "intersecion",i
              );
            }else if(state.segmentos[i][j].title === "Limite de velocidade para motocicletas" ||state.segmentos[i][j].title ===  "Limite de velocidade para caminhões"){
              const [newValidators, newSegmentos] = handlerEqualsValidators(state, state.segmentos[i][j])
              state.validators = newValidators
              state.segmentos = newSegmentos
            } else if(state.segmentos[i][j].title === "Infraestrutura para travessia de pedestres - na rodovia de inspeção"){
              handlerVaridators(
                state,
                state.segmentos[i][j],
                7,
                [
                  {
                    title: "Qualidade da travessia de pedestres",
                    code: 3,
                    text: "Não se aplica",
                  }
                ],
                "pedestrianQuality",i
              );
            }
          
        });
      }
    },
    setImagens: (state, {payload}) => {
      if(!payload.novoSegmento){

        state.imagens = payload.imagens
      }else{
        console.log("payload.segmento",payload.segmento)
        ["centre_0", "centre_20", "centre_40", "centre_60", "centre_80"].forEach(centre =>{
          Object.keys(payload.segmento).forEach((varName,i)=>{
            
          if(centre == varName){
            console.log(varName)
            state.imagens = [...state.imagens, payload.segmento[varName]]
  
          }
        })
      })
      }
    },

    setIdSection: (state, { payload }) => {
      Object.keys(state.segmentos[payload.index]).forEach((item) => {
        if (
          (state.segmentos[payload.index][item].title === "id_section" ||
            state.segmentos[payload.index][item].title === "latitude" ||
            state.segmentos[payload.index][item].title === "longitude") &&
          state.segmentos[payload.index][item].selecionado.code == undefined
        ) {
          state.segmentos[payload.index][item].selecionado.code =
            payload.item[state.segmentos[payload.index][item].title];

          console.log(
            "indexx",
            payload.index,
            payload.id,
            state.segmentos[payload.index][item].selecionado.code
          );
        }
      });
    },

    copySegmentPrevious: (state) => {
      Object.keys(state.segmentos[state.paginaAtual - 1]).forEach(i =>{
        if (!arrayNoIncludes.includes(state.segmentos[state.paginaAtual - 2][i].title)){
          Object.keys(state.segmentos[state.paginaAtual - 2]).forEach(j =>{
            console.log(state.segmentos[state.paginaAtual - 2][i].title, state.segmentos[state.paginaAtual - 2][i].selecionado.code)
              if(state.segmentos[state.paginaAtual - 2][i].title == state.segmentos[state.paginaAtual - 1][j].title){
                state.segmentos[state.paginaAtual - 1][i].selecionado.code = state.segmentos[state.paginaAtual - 2][j].selecionado.code
                state.segmentos[state.paginaAtual - 1][i].selecionado.title = state.segmentos[state.paginaAtual - 2][j].selecionado.title
              }
          })
          state.hasChanged = !state.hasChanged
          state.validators[state.paginaAtual -1] = state.validators[state.paginaAtual -2]
        }
      })
      
    },
    copySegmentPreviousAndNext: (state) => {
      Object.keys(state.segmentos[state.paginaAtual]).forEach(i =>{
        if (!arrayNoIncludes.includes(state.segmentos[state.paginaAtual - 1][i].title)){
          Object.keys(state.segmentos[state.paginaAtual - 1]).forEach(j =>{
            console.log(state.segmentos[state.paginaAtual - 1][i].title, state.segmentos[state.paginaAtual - 1][i].selecionado.code)
              if(state.segmentos[state.paginaAtual - 1][i].title == state.segmentos[state.paginaAtual][j].title){
                state.segmentos[state.paginaAtual][i].selecionado.code = state.segmentos[state.paginaAtual - 1][j].selecionado.code
                state.segmentos[state.paginaAtual][i].selecionado.title = state.segmentos[state.paginaAtual - 1][j].selecionado.title
              }
          })
          state.hasChanged = !state.hasChanged
          state.validators[state.paginaAtual] = state.validators[state.paginaAtual -1]

        }
      })

      state.paginaAtual = state.paginaAtual+1
      
    },

    setDuplicateImagesId: (state,{ payload }) =>{
        state.duplicateImagesSectionId = payload
    },
    reset: (state) => (state = initialState),
  },
});

export const {
  setCurrentSegment,
  setArrayLength,
  setSegmento,
  setAllSegments,
  setIdSection,
  setImageRef,
  saveChange,
  copySegmentPrevious,
  copySegmentPreviousAndNext,
  setImagens,
  setDuplicateImagesId,
  reset,
  setCurrentPage
} = dadosSegmentosSlice.actions;

export default dadosSegmentosSlice.reducer;
